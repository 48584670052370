import {createSlice} from '@reduxjs/toolkit'

const submitDocumentsSlice = createSlice({
  name: 'submitDocuments',
  initialState: {
    phone_number: '',
    patients: [],
    documents: [],
  },
  reducers: {
    setSubmitDocuments: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearSubmitDocuments: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const submitDocumentsReducer = submitDocumentsSlice.reducer
export const {setSubmitDocuments, clearSubmitDocuments} = submitDocumentsSlice.actions
