import React, {useEffect, useState} from 'react'
import {bool, func} from 'prop-types'
import InputModal from './InputModal'
import {INPUT_TYPES} from '../../utilities/form-helpers/FormBuilder'
import {WRP_CASE_TYPE_SLUG} from '../../constants/cases'
import {useQuery} from '@tanstack/react-query'
import {fetchDependents} from '../../api/dependents/dependents'
import {THIRTY_MINUTES_MS} from '../../constants/time'
import {fetchCaseTypes} from '../../api/case-types/caseTypes'
import {US_PHONE_REGEX} from '../../constants/regex'
import {fetchCaseEffectiveDates, fetchCaseTimes} from '../../api/cases/cases'
import {useTranslation} from 'next-i18next'
import useAuth from '../../hooks/useAuth'
import formatPhoneNumber from '../../utilities/form-helpers/formatPhoneNumber'
import useAnalytics from '../../hooks/useAnalytics'
import {ANALYTICS_EVENT} from '../../constants/analytics'

const propTypes = {
  loading: bool,
  onSubmit: func,
  show: bool,
  toggle: func,
}

const defaultProps = {
  loading: false,
  onSubmit: null,
  show: false,
  toggle: null,
}

const NewCaseModal = ({loading, onSubmit, show, toggle}) => {
  const {t} = useTranslation()
  const {user} = useAuth()
  const analytics = useAnalytics()

  const [submitType, setSubmitType] = useState(null)

  const {
    isLoading: dependentsLoading,
    data: dependentsData,
  } = useQuery(['cases', 'dependents'], () => fetchDependents(), {
    cacheTime: THIRTY_MINUTES_MS,
    staleTime: THIRTY_MINUTES_MS,
  })

  const {
    isLoading: caseTypesLoading,
    data: caseTypesData,
  } = useQuery(['case_types'], () => fetchCaseTypes(), {
    cacheTime: THIRTY_MINUTES_MS,
    staleTime: THIRTY_MINUTES_MS,
  })

  const {
    isLoading: caseTimesLoading,
    data: caseTimesData,
  } = useQuery(['case_times'], fetchCaseTimes, {
    cacheTime: THIRTY_MINUTES_MS,
    staleTime: THIRTY_MINUTES_MS,
  })

  const {
    isLoading: caseDatesLoading,
    data: caseDatesData,
  } = useQuery(['case_effective_dates'], fetchCaseEffectiveDates, {
    cacheTime: THIRTY_MINUTES_MS,
    staleTime: THIRTY_MINUTES_MS,
  })

  const dependents = dependentsData?.dependents?.map(({full_name: label, id: value}) => ({label, value}))
  const types = caseTypesData?.case_types?.map(({name: label, slug: value}) => ({label, value}))
  const times = caseTimesData?.times?.map(({name: label, slug: value}) => ({label, value}))
  const dates = caseDatesData?.effective_dates?.map(({name: label, slug: value}) => ({label, value}))

  const handleSubmit = values =>
    onSubmit(
      submitType && submitType === WRP_CASE_TYPE_SLUG
        ? {
          type: submitType,
          phoneNumber: values?.phoneNumber,
          time: values?.time,
          retirementDate: values?.retirementDate,
        }
        : {
          type: submitType,
          dependents: values?.dependents,
          phoneNumber: values?.phoneNumber,
          time: values?.time,
          description: values?.description,
        }
    )

  const handleChange = values => {
    const submitType = values?.type?.value
    setSubmitType(submitType)
  }

  useEffect(() => {
    if (show) {
      analytics(ANALYTICS_EVENT.SUBMIT_CASE_VIEW)
    }
  }, [show])

  return (
    <InputModal
      show={show}
      toggle={() => {
        toggle()
      }}
      loading={loading}
      title={t('headings.submitACase')}
      variant={INPUT_TYPES.secondary}
      onSubmit={handleSubmit}
      onChange={handleChange}
      fields={[
        {
          name: 'type',
          type: 'dropdown',
          required: true,
          label: 'form.label.type',
          placeholder: 'form.placeholder.selectType',
          loading: caseTypesLoading,
          options: types,
        },
        {
          groupClassName: submitType && submitType !== WRP_CASE_TYPE_SLUG ? '' : 'd-none',
          name: 'dependents',
          type: 'dropdown',
          isMulti: true,
          suppressLocalise: true,
          label: t('form.label.newCaseDependentLabel'),
          placeholder: t('form.placeholder.selectDependents'),
          loading: dependentsLoading,
          options: dependents,
        },
        {
          groupClassName: submitType ? '' : 'd-none',
          name: 'phoneNumber',
          required: true,
          label: 'form.label.contactPhoneNumber',
          placeholder: 'form.placeholder.enterPhoneNumber',
          error: 'form.validation.phoneNumber',
          formatter: formatPhoneNumber,
          default: formatPhoneNumber(user?.mobile_phone),
          isInvalid: mobileNumber => mobileNumber && !US_PHONE_REGEX.test(mobileNumber),
        },
        {
          groupClassName: submitType ? '' : 'd-none',
          name: 'time',
          type: 'dropdown',
          required: true,
          label: 'form.label.newCaseBestTimeLabel',
          placeholder: 'form.placeholder.selectTime',
          loading: caseTimesLoading,
          options: times,
        },
        {
          groupClassName: submitType && submitType === WRP_CASE_TYPE_SLUG ? '' : 'd-none',
          name: 'retirementDate',
          type: 'dropdown',
          label: t('form.label.retirementEffectiveDate'),
          placeholder: t('form.placeholder.select'),
          required: submitType === WRP_CASE_TYPE_SLUG,
          loading: caseDatesLoading,
          options: dates,
        },
        {
          groupClassName: submitType && submitType !== WRP_CASE_TYPE_SLUG ? '' : 'd-none',
          name: 'description',
          type: 'textarea',
          className: 'w-100',
          required: submitType !== WRP_CASE_TYPE_SLUG,
          label: 'form.label.description',
          placeholder: 'form.placeholder.addADescriptionHere',
          rows: 3,
        },
      ]}
    />
  )
}

NewCaseModal.propTypes = propTypes
NewCaseModal.defaultProps = defaultProps

export default NewCaseModal
