import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {DateTime} from 'luxon'

export const fetchUser = (id = 'me') => tcWrap(async () => {
  const {user} = await apiClient.get(`users/${id}`)
  return user
})

const userAction = (action, url, data) => tcWrap(async () => {
  const {user} = await apiClient[action](url, data).catch(handleError)
  return user
})

export const updateUser = (user, id = 'me') => userAction('put', `users/${id}`, {user})
export const deleteUser = (id = 'me') => tcWrap(async () => {
  const {deactivated} = await apiClient.delete(`users/${id}`)
  return deactivated
})

export const changePassword = (user, id = 'me') => tcWrap(async () => apiClient.post(`/users/${id}/password`, {user}))

export const verifyAccountDetails = (id = 'me') => tcWrap(async () => {
  const {user} = await apiClient.put(`users/${id}`, {
    user: {
      key_profile_information_validated_date: new DateTime(0).toFormat('yyyy-MM-dd'),
    },
  })
  return user
})
