/*
 Reference: https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939
*/
export default date => {
  if (!date) {
    return date
  }
  date = new Date(date)
  let dateWithTime = new Date()
  date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), dateWithTime.getHours(),
    dateWithTime.getMinutes(), dateWithTime.getSeconds())
  return date
}
