import styled from 'styled-components'
import PrimaryDropdown from '../../primary/PrimaryDropdown'
import {localise} from '../../../../services/LocalizationServices'
import {isMobile} from 'react-device-detect'

const DATE_PICKER_SELECT = 'date-picker-select'

const DatePickerSelect = styled(PrimaryDropdown)`
  .${DATE_PICKER_SELECT}__control {
    border: none;
  }

  .${DATE_PICKER_SELECT}__menu {
    position: absolute;
    min-width: 70px;
    .${DATE_PICKER_SELECT}__menu-list {
      border-radius: 8px;
    }
  }
`

const DatePickerHeader = ({date, changeYear, changeMonth}) => {
  const years =
    [...Array((new Date()).getFullYear() + 100).keys()]
      .filter(x => x > 1900)
      .reverse()
      .map(option => ({label: option, value: option}))

  const months = [
    'months.jan',
    'months.feb',
    'months.mar',
    'months.apr',
    'months.may',
    'months.jun',
    'months.jul',
    'months.aug',
    'months.sep',
    'months.oct',
    'months.nov',
    'months.dec',
  ].map(localise)

  return (
    <div className='d-flex align-items-start ms-3 my-2'>
      <DatePickerSelect
        classNamePrefix={DATE_PICKER_SELECT}
        value={years.find(({value}) => date?.getFullYear() === value)}
        onChange={({value}) => changeYear(value)}
        options={years}
        isSearchable={false}
        menuPortalTarget={null}
      />
      <DatePickerSelect
        classNamePrefix={DATE_PICKER_SELECT}
        className='ms-2'
        value={{label: months[date.getMonth()], value: months[date.getMonth()]}}
        onChange={({value}) => changeMonth(months.indexOf(value))}
        options={months.map(option => ({label: option, value: option}))}
        isSearchable={false}
        menuPortalTarget={isMobile ? null : document.body}
      />
    </div>
  )
}

export default DatePickerHeader
