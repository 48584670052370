export const ADDRESS_URL = '/address'
export const AUTH_URL = '/auth'
export const ARTICLES_URL = '/articles'
export const BENEFICIARIES_URL = '/beneficiaries'
export const CASES_URL = '/cases'
export const CHANGE_PASSWORD = '/change-password'
export const CLAIMS_URL = '/claims'
export const COB_DEPENDENTS = '/cob-dependents'
export const COMPARE_URL = '/compare'
export const CONFIRM_URL = '/confirm'
export const CONSIDERATIONS_URL = '/considerations'
export const CONTACT_US_URL = '/contact-us'
export const CONTINGENT_URL = '/contingent'
export const DESIGNATION_URL = '/designation'
export const CALCULATOR_URL = '/calculator'
export const DASHBOARD_URL = '/dashboard'
export const DEPENDENTS_URL = '/dependents'
export const DUES_URL = '/dues'
export const EDIT_URL = '/edit'
export const EMAIL_URL = '/email'
export const ERROR_URL = '/error'
export const ENROLLMENT_URL = '/enrollment'
export const FAMILY_URL = '/family'
export const FAQ_URL = '/faq'
export const FSB_URL = '/fsb'
export const WORK_URL = '/work'
export const IMPERSONATE_URL = '/impersonate'
export const INFORMATION_URL = '/information'
export const LIBRARY_URL = '/library'
export const MAINTENANCE_URL = '/maintenance'
export const NEW_URL = '/new'
export const NOT_FOUND_URL = '/not-found'
export const NOTIFICATIONS_URL = '/notifications'
export const OPTIONS_URL = '/options'
export const PASSWORD_URL = '/password'
export const PAYMENTS_URL = '/payments'
export const PLAN_URL = '/plan'
export const PLANS_URL = '/plans'
export const PLAN_HISTORY_URL = '/plan-history'
export const PREFERENCES_URL = '/preferences'
export const PRIMARY_URL = '/primary'
export const PRIVACY_POLICY_URL = '/privacy-policy'
export const PROFILE_URL = '/profile'
export const PROTECTED_HEALTH_INFORMATION_URL = '/protected-health-information'
export const QUALIFICATIONS_URL = '/qualifications'
export const RECURRING_URL = '/recurring'
export const RESET_PASSWORD_URL = '/reset-password'
export const RETIREE_URL = '/retiree'
export const RETURN_POLICY_URL = '/return-policy'
export const REVIEW_URL = '/review'
export const RMSP_URL = '/rmsp'
export const ROOT_URL = '/'
export const SECURITY_URL = '/security'
export const SELF_PAYMENT_URL = '/self-payment'
export const SELECT_URL = '/select'
export const SHARE_URL = '/share'
export const SIGN_IN_URL = '/sign-in'
export const SIGN_UP_URL = '/sign-up'
export const SUBMIT_DOCUMENT_URL = '/submit-document'
export const SUCCESS_URL = '/success'
export const TERMS_URL = '/terms'
export const TRANSFER_URL = '/transfer'
export const TWO_FACTOR_URL = '/two-factor'
export const VALIDATE_URL = '/validate'
export const VERIFY_URL = '/verify'



