import React from 'react'
import Loader from 'react-loaders'
import styled, {useTheme} from 'styled-components'
import BlockUi from '@availity/block-ui'
import {useSelector} from 'react-redux'
import {isLoading} from '../../store/selectors/loadingSelectors'
import '@availity/block-ui/dist/index.css'
import 'loaders.css/loaders.min.css'

const StyledBlockUi = styled(BlockUi)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: ${props => props?.$isActive ? 2000 : -1};
`

const LoadingIndicator = ({active = false}) => {
  const theme = useTheme()
  const isActive = useSelector(isLoading) || active
  const loaderStyles = {position: 'relative', left: '50%'}

  return (
    <StyledBlockUi
      tag='div'
      hidden={!isActive}
      blocking={isActive}
      keepInView
      $isActive={isActive}
      loader={(
        <Loader
          active={isActive}
          style={loaderStyles}
          type='ball-scale-ripple-multiple'
          color={theme?.colors?.app?.accent}
        />
      )}
    />
  )
}

export default LoadingIndicator
