import React from 'react'
import {array, element, node, number, oneOf, oneOfType, string} from 'prop-types'
import {OverlayTrigger, Button, Tooltip as BSToolTip, Popover} from 'react-bootstrap'
import styled from 'styled-components'
import Text from '../text/Text'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import {screenMinLg} from '../../stylesheets/mixins/screens'

const propTypes = {
  className: string,
  children: element,
  delay: number,
  font: oneOf(Object.values(TEXT_TYPES)),
  placement: oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
  ]),
  text: node,
  trigger: oneOfType([array, string]),
}

const defaultProps = {
  className: '',
  children: <Button>defaultButton</Button>,
  delay: 200,
  font: TEXT_TYPES.detailRegularHint,
  placement: 'top',
  text: 'tooltipText',
  trigger: ['hover', 'focus'],
}

const StyledToolTip = styled(BSToolTip)`
  &.show {opacity: 1;}
  .tooltip-arrow {display: none}
  .tooltip-inner {
    padding: 15px 16px 13px 20px;
    border-radius: 8px;
    box-shadow: ${props => props?.theme?.shadows?.dropdownCards};
    background-color: white;
    text-align: left;
    ${screenMinLg({maxWidth: '400px'})}
  }
`

const Tooltip = ({className, children, delay, font, placement, text, trigger, ...rest}) => {
  const renderTooltip = props => (
    <StyledToolTip id={rest?.id} className={`tooltip ${className}`} {...props}>
      <Text suppressLocalise variant={font}>{text}</Text>
    </StyledToolTip>
  )

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      delay={delay}
      overlay={renderTooltip}
      {...rest}>
      {children}
    </OverlayTrigger>
  )
}

Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default Tooltip
