import {createSlice} from '@reduxjs/toolkit'

const promptSlice = createSlice({
  name: 'prompt',
  initialState: {
    active: false,
  },
  reducers: {
    setPrompt: (state, action) => {
      state.active = action?.payload
    },
  },
})

export const promptReducer = promptSlice.reducer
export const {setPrompt} = promptSlice.actions
