import {useTheme} from 'styled-components'
import {useMemo} from 'react'

export const TEXT_TYPES = {
  H1LightPrimary: 'H1LightPrimary',
  H2LightPrimary: 'H2LightPrimary',
  H3LightPrimary: 'H3LightPrimary',
  H4LightPrimary: 'H4LightPrimary',
  bodyLightPrimary: 'bodyLightPrimary',
  subheadLightPrimary: 'subheadLightPrimary',
  footnoteLightPrimary: 'footnoteLightPrimary',
  detailLightPrimary: 'detailLightPrimary',
  captionLightPrimary: 'captionLightPrimary',
  H1RegularPrimary: 'H1RegularPrimary',
  H2RegularPrimary: 'H2RegularPrimary',
  H3RegularPrimary: 'H3RegularPrimary',
  H4RegularPrimary: 'H4RegularPrimary',
  bodyRegularPrimary: 'bodyRegularPrimary',
  subheadRegularPrimary: 'subheadRegularPrimary',
  footnoteRegularPrimary: 'footnoteRegularPrimary',
  detailRegularPrimary: 'detailRegularPrimary',
  captionRegularPrimary: 'captionRegularPrimary',
  H1BoldPrimary: 'H1BoldPrimary',
  H2BoldPrimary: 'H2BoldPrimary',
  H3BoldPrimary: 'H3BoldPrimary',
  H4BoldPrimary: 'H4BoldPrimary',
  bodyBoldPrimary: 'bodyBoldPrimary',
  subheadBoldPrimary: 'subheadBoldPrimary',
  footnoteBoldPrimary: 'footnoteBoldPrimary',
  detailBoldPrimary: 'detailBoldPrimary',
  captionBoldPrimary: 'captionBoldPrimary',
  H1LightSecondary: 'H1LightSecondary',
  H2LightSecondary: 'H2LightSecondary',
  H3LightSecondary: 'H3LightSecondary',
  H4LightSecondary: 'H4LightSecondary',
  bodyLightSecondary: 'bodyLightSecondary',
  subheadLightSecondary: 'subheadLightSecondary',
  footnoteLightSecondary: 'footnoteLightSecondary',
  detailLightSecondary: 'detailLightSecondary',
  captionLightSecondary: 'captionLightSecondary',
  H1RegularSecondary: 'H1RegularSecondary',
  H2RegularSecondary: 'H2RegularSecondary',
  H3RegularSecondary: 'H3RegularSecondary',
  H4RegularSecondary: 'H4RegularSecondary',
  bodyRegularSecondary: 'bodyRegularSecondary',
  subheadRegularSecondary: 'subheadRegularSecondary',
  footnoteRegularSecondary: 'footnoteRegularSecondary',
  detailRegularSecondary: 'detailRegularSecondary',
  captionRegularSecondary: 'captionRegularSecondary',
  H1BoldSecondary: 'H1BoldSecondary',
  H2BoldSecondary: 'H2BoldSecondary',
  H3BoldSecondary: 'H3BoldSecondary',
  H4BoldSecondary: 'H4BoldSecondary',
  bodyBoldSecondary: 'bodyBoldSecondary',
  subheadBoldSecondary: 'subheadBoldSecondary',
  footnoteBoldSecondary: 'footnoteBoldSecondary',
  detailBoldSecondary: 'detailBoldSecondary',
  captionBoldSecondary: 'captionBoldSecondary',
  H1LightAccent: 'H1LightAccent',
  H2LightAccent: 'H2LightAccent',
  H3LightAccent: 'H3LightAccent',
  H4LightAccent: 'H4LightAccent',
  bodyLightAccent: 'bodyLightAccent',
  subheadLightAccent: 'subheadLightAccent',
  footnoteLightAccent: 'footnoteLightAccent',
  detailLightAccent: 'detailLightAccent',
  captionLightAccent: 'captionLightAccent',
  H1RegularAccent: 'H1RegularAccent',
  H2RegularAccent: 'H2RegularAccent',
  H3RegularAccent: 'H3RegularAccent',
  H4RegularAccent: 'H4RegularAccent',
  bodyRegularAccent: 'bodyRegularAccent',
  subheadRegularAccent: 'subheadRegularAccent',
  footnoteRegularAccent: 'footnoteRegularAccent',
  detailRegularAccent: 'detailRegularAccent',
  captionRegularAccent: 'captionRegularAccent',
  H1BoldAccent: 'H1BoldAccent',
  H2BoldAccent: 'H2BoldAccent',
  H3BoldAccent: 'H3BoldAccent',
  H4BoldAccent: 'H4BoldAccent',
  bodyBoldAccent: 'bodyBoldAccent',
  subheadBoldAccent: 'subheadBoldAccent',
  footnoteBoldAccent: 'footnoteBoldAccent',
  detailBoldAccent: 'detailBoldAccent',
  captionBoldAccent: 'captionBoldAccent',
  H1LightDestructive: 'H1LightDestructive',
  H2LightDestructive: 'H2LightDestructive',
  H3LightDestructive: 'H3LightDestructive',
  H4LightDestructive: 'H4LightDestructive',
  bodyLightDestructive: 'bodyLightDestructive',
  subheadLightDestructive: 'subheadLightDestructive',
  footnoteLightDestructive: 'footnoteLightDestructive',
  detailLightDestructive: 'detailLightDestructive',
  captionLightDestructive: 'captionLightDestructive',
  H1RegularDestructive: 'H1RegularDestructive',
  H2RegularDestructive: 'H2RegularDestructive',
  H3RegularDestructive: 'H3RegularDestructive',
  H4RegularDestructive: 'H4RegularDestructive',
  bodyRegularDestructive: 'bodyRegularDestructive',
  subheadRegularDestructive: 'subheadRegularDestructive',
  footnoteRegularDestructive: 'footnoteRegularDestructive',
  detailRegularDestructive: 'detailRegularDestructive',
  captionRegularDestructive: 'captionRegularDestructive',
  H1BoldDestructive: 'H1BoldDestructive',
  H2BoldDestructive: 'H2BoldDestructive',
  H3BoldDestructive: 'H3BoldDestructive',
  H4BoldDestructive: 'H4BoldDestructive',
  bodyBoldDestructive: 'bodyBoldDestructive',
  subheadBoldDestructive: 'subheadBoldDestructive',
  footnoteBoldDestructive: 'footnoteBoldDestructive',
  detailBoldDestructive: 'detailBoldDestructive',
  captionBoldDestructive: 'captionBoldDestructive',
  H1LightHint: 'H1LightHint',
  H2LightHint: 'H2LightHint',
  H3LightHint: 'H3LightHint',
  H4LightHint: 'H4LightHint',
  bodyLightHint: 'bodyLightHint',
  subheadLightHint: 'subheadLightHint',
  footnoteLightHint: 'footnoteLightHint',
  detailLightHint: 'detailLightHint',
  captionLightHint: 'captionLightHint',
  H1RegularHint: 'H1RegularHint',
  H2RegularHint: 'H2RegularHint',
  H3RegularHint: 'H3RegularHint',
  H4RegularHint: 'H4RegularHint',
  bodyRegularHint: 'bodyRegularHint',
  subheadRegularHint: 'subheadRegularHint',
  footnoteRegularHint: 'footnoteRegularHint',
  detailRegularHint: 'detailRegularHint',
  captionRegularHint: 'captionRegularHint',
  H1BoldHint: 'H1BoldHint',
  H2BoldHint: 'H2BoldHint',
  H3BoldHint: 'H3BoldHint',
  H4BoldHint: 'H4BoldHint',
  bodyBoldHint: 'bodyBoldHint',
  subheadBoldHint: 'subheadBoldHint',
  footnoteBoldHint: 'footnoteBoldHint',
  detailBoldHint: 'detailBoldHint',
  captionBoldHint: 'captionBoldHint',
  H1LightBrand1: 'H1LightBrand1',
  H2LightBrand1: 'H2LightBrand1',
  H3LightBrand1: 'H3LightBrand1',
  H4LightBrand1: 'H4LightBrand1',
  bodyLightBrand1: 'bodyLightBrand1',
  subheadLightBrand1: 'subheadLightBrand1',
  footnoteLightBrand1: 'footnoteLightBrand1',
  detailLightBrand1: 'detailLightBrand1',
  captionLightBrand1: 'captionLightBrand1',
  H1RegularBrand1: 'H1RegularBrand1',
  H2RegularBrand1: 'H2RegularBrand1',
  H3RegularBrand1: 'H3RegularBrand1',
  H4RegularBrand1: 'H4RegularBrand1',
  bodyRegularBrand1: 'bodyRegularBrand1',
  subheadRegularBrand1: 'subheadRegularBrand1',
  footnoteRegularBrand1: 'footnoteRegularBrand1',
  detailRegularBrand1: 'detailRegularBrand1',
  captionRegularBrand1: 'captionRegularBrand1',
  H1BoldBrand1: 'H1BoldBrand1',
  H2BoldBrand1: 'H2BoldBrand1',
  H3BoldBrand1: 'H3BoldBrand1',
  H4BoldBrand1: 'H4BoldBrand1',
  bodyBoldBrand1: 'bodyBoldBrand1',
  subheadBoldBrand1: 'subheadBoldBrand1',
  footnoteBoldBrand1: 'footnoteBoldBrand1',
  detailBoldBrand1: 'detailBoldBrand1',
  captionBoldBrand1: 'captionBoldBrand1',
}

const useTextStyle = (variant = TEXT_TYPES.bodyRegularPrimary) => {
  const {colors, fonts} = useTheme()

  const fontFamily = fonts?.outfit?.family

  return useMemo(() => {
    if (variant) {
      const [size, weight, color] = variant.split(/(?=[A-Z])/)

      return {
        fontFamily,
        size: fonts?.sizes[size],
        fontWeight: fonts?.outfit?.weights[weight.toLowerCase()],
        color: colors?.text[color.toLowerCase()],
      }
    }
  }, [variant])
}

export const applyFontToStyle = ({color, fontFamily, fontWeight, size: fontSize}) => (
  {
    fontFamily,
    fontWeight,
    fontSize,
    color,
  }
)

export default useTextStyle
