import React from 'react'
import {array, node, oneOf, string} from 'prop-types'
import {Image} from 'react-bootstrap'
import {INFO_ICON} from '../../constants/images'
import Tooltip from './Tooltip'
import styled from 'styled-components'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  className: string,
  font: oneOf(Object.values(TEXT_TYPES)),
  icon: string,
  text: node,
  trigger: array,
}

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  line-height: 16px;
`

const StyledImage = styled(Image)`
  transition: opacity .3s;
  &:hover {
    cursor: pointer;
    opacity: .3;
  }
  
  &:focus-visible {}
`

const InfoTooltip = props => {
  const {
    className = '',
    font = TEXT_TYPES.detailRegularPrimary,
    icon = INFO_ICON,
    text = '',
    trigger = ['click'],
    ...rest
  } = props

  return (
    <Tooltip
      font={font}
      placement='auto-end'
      text={text}
      trigger={trigger}
      rootClose
      {...rest}>
      <StyledButton
        type='button'
        className={className}
        aria-describedby={rest?.id}
        aria-label={localise('aria.additionalInformation')}>
        <StyledImage
          src={icon}
          aria-hidden
          focusable={false}
          alt={localise('alt.infoIcon')}
        />
      </StyledButton>
    </Tooltip>
  )
}

InfoTooltip.propTypes = propTypes

export default InfoTooltip
