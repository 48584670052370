import styled from 'styled-components'
import {Form, FormControl, Image} from 'react-bootstrap'
import Error from '../error/Error'
import React, {useEffect, useState} from 'react'
import {localise as L, localise} from '../../../services/LocalizationServices'
import {PASSWORD_HIDE_ICON, PASSWORD_SHOW_ICON} from '../../../constants/images'

const StyledInput = styled(FormControl)`
  &,
  textarea {padding: 8px}
  
  &:not(textarea) {height: ${props => props?.theme?.inputs?.height}}
  
  box-shadow:none !important;
  background-image: none !important;
  background-color: transparent;
  &[readonly] {background-color: transparent}
  :focus {background-color: transparent}

  &.is-invalid,
  &:valid.is-invalid {border-color: ${props => props?.theme?.colors?.app?.destructive}}
  &:focus-visible {outline: none}
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

const PasswordRevealButton = styled.button`
  background-color: transparent;
  border: none;
  float: right;
  margin-top: -33px;
`

const defaultHandleChange = (e, setStateHandler) => setStateHandler(e?.target?.value)

const Input = props => {
  const {
    className,
    defaultValue,
    error,
    handler,
    information,
    isInvalid,
    key,
    label,
    maxLength,
    name,
    placeholder = null,
    readOnly,
    required,
    suppressLocalise,
    type,
    value,
    ...rest
  } = props

  const [textType, setTextType] = useState(type)
  const [visited, setVisited] = useState(false)
  const [valueEntered, setValueEntered] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setInvalid(!!visited && !!valueEntered && !!required && !value)
    setErrorMessage(
      !!visited && !!valueEntered && !!required && !value
        ? localise('form.validation.requiredField')
        : ''
    )
  }, [required, value, visited])

  useEffect(() => {
    if (!!value) setValueEntered(true)
  }, [value])

  return (
    <>
      <StyledInput
        className={className}
        defaultValue={defaultValue}
        isInvalid={isInvalid || invalid || false}
        key={key}
        maxLength={maxLength}
        name={name}
        onChange={e => defaultHandleChange(e, handler)}
        placeholder={placeholder === null ? '' : suppressLocalise ? placeholder || label : L(placeholder || label)}
        readOnly={readOnly}
        required={required}
        type={textType}
        value={value}
        onFocus={() => setVisited(true)}
        as={type === 'textarea' ? 'textarea' : null}
        {...rest}
      />
      {
        type === 'password' &&
        <PasswordRevealButton
          type='button'
          onClick={() => setTextType(textType === 'text' ? 'password' : 'text')}>
          <Image
            src={textType === 'text' ? PASSWORD_HIDE_ICON : PASSWORD_SHOW_ICON}
            alt={localise(textType === 'text' ? 'alt.passwordHideIcon' : 'alt.passwordShowIcon')}
          />
        </PasswordRevealButton>
      }
      {
        !!(error || errorMessage) && !!(isInvalid || invalid)
          ? type === 'number'
            ? (
              <Error
                error={(
                  isInvalid
                    ? error
                    : invalid
                      ? errorMessage
                      : ''
                )}
                suppressLocalise={suppressLocalise}
              />
            )
            : (
              <Form.Control.Feedback type='invalid'>
                <Error
                  error={(
                    isInvalid
                      ? error
                      : invalid
                        ? errorMessage
                        : ''
                  )}
                  suppressLocalise={suppressLocalise}
                />
              </Form.Control.Feedback>
            )
          : null
      }
    </>
  )
}

export default Input
