import React from 'react'
import {bool, func, node, object, oneOfType, string} from 'prop-types'
import {Button, Modal as BSModal} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import styled from 'styled-components'

const propTypes = {
  className: string,
  bodyClasses: string,
  footerClasses: string,
  headerClasses: string,
  animation: bool,
  bodyElement: node,
  bodyText: oneOfType([string, object]),
  footerElement: node,
  headerElement: node,
  headerText: node,
  hideFooter: bool,
  show: bool,
  showCloseButton: bool,
  onConfirm: func,
  toggle: func,
}

const defaultProps = {
  className: '',
  bodyClasses: '',
  footerClasses: '',
  headerClasses: '',
  animation: true,
  bodyElement: null,
  bodyText: '',
  footerElement: null,
  headerElement: null,
  hideFooter: false,
  headerText: '',
  show: false,
  showCloseButton: false,
  onConfirm: null,
  toggle: null,
}

const StyledModal = styled(BSModal)`
  &.modal-container {
    .modal-content {
      border-radius: 16px;
    }
  }
`

const Modal = props => {

  const {
    className,
    bodyClasses,
    footerClasses,
    headerClasses,
    animation,
    bodyElement,
    bodyText,
    footerElement,
    headerElement,
    headerText,
    hideFooter,
    show,
    showCloseButton,
    onConfirm,
    toggle,
  } = props

  const defaultHeader = <BSModal.Title><div className='font-20 font-bold'>{headerText}</div></BSModal.Title>

  const defaultBody = <span className='font-15'>{bodyText}</span>

  const defaultFooter = (
    <Button id='modal-ok' variant='outline' onClick={onConfirm}>
      {localise('buttonText.ok')}
    </Button>
  )

  return (
    <StyledModal
      show={show}
      onHide={toggle}
      animation={animation}
      className={`modal-container ${className}`}>
      <BSModal.Header
        hidden={!headerElement && !headerText}
        closeButton={showCloseButton}
        className={`border-0 ${headerClasses}`}>
        {headerElement || defaultHeader}
      </BSModal.Header>
      <BSModal.Body hidden={!bodyElement && !bodyText} className={bodyClasses}>
        {bodyElement || defaultBody}
      </BSModal.Body>
      {
        !hideFooter &&
        <BSModal.Footer className={`border-0 ${footerClasses}`}>
          {footerElement || defaultFooter}
        </BSModal.Footer>
      }
    </StyledModal>
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal