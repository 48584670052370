import {PLUS_SYMBOL_OR_NUMBERS} from '../../constants/regex'

export default phoneNumber => {
  try {
    for (const char of phoneNumber) {
      if (!PLUS_SYMBOL_OR_NUMBERS.test(char)) {
        phoneNumber = phoneNumber.replace(char, '')
      }
    }

    return phoneNumber
  } catch (e) {console.error(e)}
}
