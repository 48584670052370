export const ANALYTICS_EVENT = {
  LANDING_VIEW: 'Landing View',
  LANDING_VIEW_HELPFUL_LINK: 'Landing View Helpful Link',
  LANDING_VIEW_NEWS_INFO: 'Landing View News Info',
  SIGN_UP_VIEW: 'Sign Up View',
  SIGN_UP_REGISTERED: 'Sign Up Registered',
  SIGN_IN_VIEW: 'Sign In View',
  SIGNED_IN: 'Signed In',
  RESET_PASSWORD_VIEW: 'Reset Password View',
  RESET_PASSWORD: 'Reset Password',
  TWO_FACTOR_ENTER_CODE_VIEW: 'Two Factor Enter Code View',
  TWO_FACTOR_RESEND_CODE: 'Two Factor Resend Code',
  TWO_FACTOR_ENTERED_CODE: 'Two Factor Entered Code',
  DASHBOARD_VIEW: 'Dashboard View',
  DASHBOARD_BANNER_SELECTED: 'Dashboard Banner Selected',
  MARKETPLACE_VIEW: 'Marketplace View',
  NOTIFICATIONS_VIEW: 'Notifications View',
  NOTIFICATIONS_ACTION: 'Notifications Action',
  FILTERS_VIEW: 'Filters View',
  HOURS_VIEW: 'Hours View',
  HOURS_EXPORT_ALL: 'Hours Export All',
  HOURS_EXPORT_FILTERED: 'Hours Export Filtered',
  HOURS_FILTERS_APPLIED: 'Hours Filters Applied',
  HOURS_FILTERS_REMOVED: 'Hours Filters Removed',
  HOURS_SORT: 'Hours Sort',
  HOURS_SEARCH: 'Hours Search',
  HOURS_MONTHLY_CONTRIBUTIONS_VIEW: 'Hours Monthly Contributions View',
  QTR_STATEMENTS_VIEW: 'Quarterly Statements View',
  QTR_STATEMENTS_SORT: 'Quarterly Statements Sort',
  QTR_STATEMENTS_DOC_REQUESTED: 'Quarterly Statements Document Requested',
  QTR_STATEMENTS_DOC_VIEW: 'Quarterly Statements Document View',
  REQUIRED_NOTICES_VIEW: 'Required Notices View',
  REQUIRED_NOTICES_DOC_REQUESTED: 'Required Notices Document Requested',
  REQUIRED_NOTICES_DOC_VIEW: 'Required Notices Document View',
  REQUIRED_NOTICES_SORT: 'Required Notices Sort',
  REQUIRED_NOTICES_SEARCH: 'Required Notices Search',
  BENEFIT_DOCUMENTS_VIEW: 'Benefit Documents View',
  BENEFIT_DOCUMENTS_DOC_REQUESTED: 'Benefit Documents Document Requested',
  BENEFIT_DOCUMENTS_DOC_VIEW: 'Benefit Documents Document View',
  BENEFIT_DOCUMENTS_FILTERS_APPLIED: 'Benefit Documents Filters Applied',
  BENEFIT_DOCUMENTS_FILTERS_REMOVED: 'Benefit Documents Filters Removed',
  BENEFIT_DOCUMENTS_SORT: 'Benefit Documents Sort',
  BENEFIT_DOCUMENTS_SEARCH: 'Benefit Documents Search',
  SIGNED_DOCUMENTS_VIEW: 'Signed Documents View',
  SIGNED_DOCUMENTS_DOC_REQUESTED: 'Signed Documents Document Requested',
  SIGNED_DOCUMENTS_DOC_VIEW: 'Signed Documents Document View',
  SIGNED_DOCUMENTS_FILTERS_APPLIED: 'Signed Documents Filters Applied',
  SIGNED_DOCUMENTS_FILTERS_REMOVED: 'Signed Documents Filters Removed',
  SIGNED_DOCUMENTS_SORT: 'Signed Documents Sort',
  SIGNED_DOCUMENTS_SEARCH: 'Signed Documents Search',
  SUMMARY_PLAN_DESC_VIEW: 'Summary Plan Description View',
  SUMMARY_PLAN_DESC_DOC_REQUESTED: 'Summary Plan Description Document Requested',
  SUMMARY_PLAN_DESC_DOC_VIEW: 'Summary Plan Description Document View',
  SUMMARY_PLAN_DESC_FILTERS_APPLIED: 'Summary Plan Description Filters Applied',
  SUMMARY_PLAN_DESC_FILTERS_REMOVED: 'Summary Plan Description Filters Removed',
  SUMMARY_PLAN_DESC_SORT: 'Summary Plan Description Sort',
  SUMMARY_PLAN_DESC_SEARCH: 'Summary Plan Description Search',
  COB_DOCUMENTS_VIEW: 'COB Documents View',
  COB_DOCUMENTS_DOC_REQUESTED: 'COB Documents Document Requested',
  COB_DOCUMENTS_DOC_VIEW: 'COB Documents Document View',
  COB_DOCUMENTS_FILTERS_APPLIED: 'COB Documents Filters Applied',
  COB_DOCUMENTS_SORT: 'COB Documents Sort',
  COB_DOCUMENTS_SEARCH: 'COB Documents Search',
  COB_UPDATE_INFO_SELECT_DEPENDENT_VIEW: 'COB Update Info Select Dependent View',
  COB_INFORMATION_UPDATED: 'COB Information Updated',
  COB_UPDATE_INFO_ENTER_SPOUSE_INFO_VIEW: 'COB Update Info Enter Spouse Info View',
  COB_UPDATE_INFO_ENTER_DEPENDENT_INFO_VIEW: 'COB Update Info Enter Dependent Information View',
  TAX_DOCUMENTS_VIEW: 'Tax Documents View',
  TAX_DOCUMENTS_DOC_REQUESTED: 'Tax Documents Document Requested',
  TAX_DOCUMENTS_DOC_VIEW: 'Tax Documents Document View',
  TAX_DOCUMENTS_SORT: 'Tax Documents Sort',
  TAX_DOCUMENTS_SEARCH: 'Tax Documents Search',
  CASES_VIEW: 'Cases View',
  CASES_FILTERS_APPLIED: 'Cases Filters Applied',
  CASES_FILTERS_REMOVED: 'Cases Filters Removed',
  CASES_SORT: 'Cases Sort',
  CASES_SEARCH: 'Cases Search',
  SUBMIT_CASE_VIEW: 'Submit Case View',
  SUBMIT_CASE_CREATED: 'Submit Case Created',
  CASE_DETAILS_VIEW: 'Case Details View',
  CASE_DETAILS_ADD_COMMENT: 'Case Details Add Comment',
  CASE_DETAILS_UPLOAD_DOCUMENTS: 'Case Details Upload Document',
  RMSP_VIEW: 'RMSP View',
  RMSP_FILTERS_APPLIED: 'RMSP Filters Applied',
  RMSP_FILTERS_REMOVED: 'RMSP Filters Removed',
  RMSP_SORT: 'RMSP Sort',
  RMSP_EXPORT_ALL: 'RMSP Export All',
  RMSP_EXPORT_FILTERED: 'RMSP Export Filtered',
  RMSP_TRANSFER_GET_STARTED_VIEW: 'RMSP Transfer Get Started View',
  RMSP_TRANSFER_BASIC_INFO_VIEW: 'RMSP Transfer Basic Info View',
  RMSP_TRANSFER_CREDITS_VIEW: 'RMSP Transfer Credits View',
  RMSP_TRANSFER_SIGNATURE_ADDED: 'RMSP Transfer Signature Added',
  RMSP_TRANSFER_COMPLETE: 'RMSP Transfer Complete',
  RMSP_TRANSFER_HISTORY_VIEW: 'RMSP Transfer History View',
  CLAIMS_VIEW: 'Claims View',
  CLAIMS_DOCUMENT_REQUESTED: 'Claims Document Requested',
  CLAIMS_DOCUMENT_VIEW: 'Claims Document View',
  CLAIMS_FILTERS_APPLIED: 'Claims Filters Applied',
  CLAIMS_FILTERS_REMOVED: 'Claims Filters Removed',
  CLAIMS_SORT: 'Claims Sort',
  CLAIMS_EXPORT_ALL: 'Claims Export All',
  CLAIMS_EXPORT_FILTERED: 'Claims Export Filtered',
  CLAIMS_SEARCH: 'Claims Search',
  FAMILY_VIEW: 'Family View',
  FAMILY_IMPORTANT_VIEW: 'Family Information View',
  DEPENDENT_DETAILS_VIEW: 'Dependent Details View',
  DEPENDENT_DETAILS_UPDATED: 'Dependent Details Updated',
  LCE_SUBMIT_VIEW: 'LCE Submit View',
  LCE_EDIT_FAMILY_VIEW: 'LCE Edit Family View',
  LCE_CREATED: 'LCE Created',
  DUES_VIEW: 'Dues View',
  DUES_PAYMENT_VIEW: 'Dues Payment View',
  DUES_PAID: 'Dues Paid',
  DUES_CANCEL_RECURRING: 'Dues Cancel Recurring',
  SELF_PAYMENTS_VIEW: 'Self Payments View',
  SELF_PAYMENTS_PAYMENT_VIEW: 'Self Payments Payment View',
  SELF_PAYMENTS_PAID: 'Self Payments Paid',
  PROFILE_VIEW: 'Profile View',
  SIGN_OUT: 'Sign Out',
  DELETE_ACCOUNT: 'Delete Account',
  EDIT_PROFILE_VIEW: 'Edit Profile View',
  EDIT_PROFILE: 'Edit Profile',
  CHANGE_EMAIL_VIEW: 'Change Email View',
  CHANGE_EMAIL_UPDATED: 'Change Email Updated',
  ADDRESS_VIEW: 'Address View',
  ADDRESS_DELETE: 'Address Delete',
  ADDRESS_MAKE_DEFAULT: 'Address Make Default',
  ADDRESS_EDIT_VIEW: 'Address Edit View',
  ADDRESS_EDITED: 'Address Edited',
  ADDRESS_CREATE_VIEW: 'Address Create View',
  ADDRESS_CREATED: 'Address Created',
  ADDRESS_VALIDATE_VIEW: 'Address Validate View',
  ADDRESS_VALIDATE_ORIGINAL: 'Address Validate Original',
  ADDRESS_VALIDATE_SUGGESTED: 'Address Validate Suggested',
  PHI_VIEW: 'PHI View',
  PHI_SET_PIN_VIEW: 'PHI Set Pin View',
  PHI_PIN_SET: 'PHI Pin Set',
  PHI_CREATE_REPRESENTATIVE_VIEW: 'PHI Create Representative View',
  PHI_REPRESENTATIVE_CREATED: 'PHI Representative Created',
  SELECT_DEPENDENT_VIEW: 'Select Dependent View',
  BENEFICIARY_VIEW: 'Beneficiary View',
  BENEFICIARY_ALLOCATION_VIEW: 'Beneficiary Allocation View',
  BENEFICIARY_ALLOCATION_UPDATED: 'Beneficiary Allocation Updated',
  BENEFICIARY_SELECTION_VIEW: 'Beneficiary Selection View',
  BENEFICIARY_EDIT_VIEW: 'Beneficiary Edit View',
  BENEFICIARY_CREATE_VIEW: 'Beneficiary CREATE View',
  BENEFICIARY_UPDATED: 'Beneficiary Updated',
  BENEFICIARY_CREATED: 'Beneficiary Created',
  PLAN_HISTORY_VIEW: 'Plan History View',
  CARD_HEALTHCARE_REQUESTED: 'Card Healthcare Requested',
  CARD_HEALTHCARE_VIEW: 'Card Healthcare View',
  CARD_VENDOR_CARD_REQUESTED: 'Card Vendor View Requested',
  CARD_VENDOR_CARD_VIEW: 'Card Vendor View',
  COMM_PREFS_VIEW: 'Comm Prefs View',
  COMM_PREFS_SMS_UPDATE: 'Comm Prefs SMS Update',
  COMM_PREFS_UNSUB_ALL: 'Comm Prefs SMS Unsubscribe All',
  COMM_PREFS_EMAIL_UPDATE: 'Comm Prefs Email Update',
  COMM_PREFS_EMAIL_UNSUB_ALL: 'Comm Prefs Email Unsubscribe All',
  COMM_PREFS_DOCUSIGN_VIEW: 'Comm Prefs Docusign View',
  CHANGE_PASSWORD_VIEW: 'Change Password View',
  CHANGE_PASSWORD_UPDATED: 'Change Password Updated',
  SECURITY_VIEW: 'Security View',
  FAQ_VIEW: 'FAQ View',
  FAQ_SORT: 'FAQ Sort',
  FAQ_SEARCH: 'FAQ Search',
  FAQ_ARTICLE_VIEW: 'FAQ Article View',
  FAQ_ARTICLE_RATE: 'FAQ Article Rate',
  CONTACT_US_DETAILS_VIEW: 'Contact Us Details View',
  CONTACT_US_ADDRESS_PRESSED: 'Contact Us Address Pressed',
  CONTACT_US_PHONE_PRESSED: 'Contact Us Phone Pressed',
  CONTACT_US_WEBSITE_PRESSED: 'Contact Us Website Pressed',
  CONTACT_US_LINK_PRESSED: 'Contact Us Link Pressed',
  PRIVACY_POLICY_VIEW: 'Privacy Policy View',
  ENROLLMENT_WIZ_TERMS_VIEW: 'Enrollment Wizard Terms View',
  ENROLLMENT_WIZ_PROVIDE_INFO_VIEW: 'Enrollment Wizard Provide Info View',
  ENROLLMENT_WIZ_PLAN_OPTIONS_VIEW: 'Enrollment Wizard Plan Options View',
  ENROLLMENT_WIZ_CHANGE_TIER: 'Enrollment Wizard Change Tier',
  ENROLLMENT_WIZ_COMPARE_PLANS_VIEW: 'Enrollment Wizard Compare Plans View',
  ENROLLMENT_WIZ_COMPARE_PLAN_CHANGE_TIER: 'Enrollment Wizard Compare Plans Change Tier',
  ENROLLMENT_WIZ_OHC_CONSIDERATION_VIEW: 'Enrollment Wizard OHC Considerations View',
  ENROLLMENT_WIZ_REVIEW_PLAN_VIEW: 'Enrollment Wizard Review Plan View',
  ENROLLMENT_WIZ_SIGNATURE_SIGNED: 'Enrollment Wizard Signature Signed',
  ENROLLMENT_WIZ_SUCCESS_VIEW: 'Enrollment Wizard Success View',
  ENROLLMENT_WIZ_ENROLLED: 'Enrollment Wizard Enrolled',
  PLAN_DETAILS_VIEW: 'Plan Details View',
  PLAN_CHANGE_PLAN: 'Plan Change Plan',
  DISPATCH_HISTORY_VIEW: 'Dispatch History View',
  DISPATCH_HISTORY_SEARCH: 'Dispatch History Search',
  DISPATCH_HISTORY_SORT: 'Dispatch History Sort',
  DISPATCH_HISTORY_FILTERS_APPLIED: 'Dispatch History Filters Applied',
  DISPATCH_HISTORY_FILTERS_REMOVED: 'Dispatch History Filters Removed',
  DISPATCH_HISTORY_EXPORT_ALL: 'Dispatch History Export All',
  DISPATCH_HISTORY_EXPORT_FILTERED: 'Dispatch History Export Filtered',
  QUALIFICATIONS_VIEW: 'Qualifications View',
  QUALIFICATIONS_SEARCH: 'Qualifications Search',
  QUALIFICATIONS_SORT: 'Qualifications Sort',
  SUBMIT_DOCUMENT_VIEW: 'Submit Document View',
  SUBMIT_DOCUMENT_SUCCESS: 'Submit Document Success',
  CASE_DETAILS_SIGNED_DOCUMENT_VIEWED: 'Case Details Signed Document Viewed',
  CASE_DETAILS_FSB_RESUBMITTED: 'Case Details FSB Resubmitted',
  MEMBERSHIP_CARD_VIEW: 'Membership Card View',
  MEMBERSHIP_CARD_ADDED_TO_WALLET: 'Membership Card Added To Wallet',
}
