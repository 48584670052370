import {createSlice} from '@reduxjs/toolkit'

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {},
  reducers: {
    setPayment: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearPayment: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const paymentReducer = paymentSlice.reducer
export const {setPayment, clearPayment} = paymentSlice.actions
