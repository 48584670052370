import React from 'react'
import {node, string} from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  children: node,
  className: string,
}

const defaultProps = {
  children: null,
  className: '',
}

const CELL_SIZE = '2.8rem'
const BORDER_RADIUS = '8px'

const StyledCalendar = styled.div`
  &.react-datepicker {
    border: none;
    border-radius: ${BORDER_RADIUS};
    box-shadow: ${props => props?.theme?.shadows?.dropdownCards};
    
    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: ${props => props?.theme?.colors?.app?.background};
        border: none;
        border-top-left-radius: ${BORDER_RADIUS};
        border-top-right-radius: ${BORDER_RADIUS};
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            width: ${CELL_SIZE};
          }
        }
      }
      
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            transition: background-color .2s;
            width: ${CELL_SIZE};
            line-height: ${CELL_SIZE};
            &:hover,
            &.react-datepicker__day--selected,
            &.react-datepicker__day--keyboard-selected {
              border-radius: 0;
              background-color: ${props => props?.theme?.colors?.app?.accent};
              color: ${props => props?.theme?.colors?.text?.secondary};
            }
          }
        }
      }
    }
  }
`

const CalendarContainer = ({className, children}) => (
  <StyledCalendar className={className}>
    {children}
  </StyledCalendar>
)

CalendarContainer.propTypes = propTypes
CalendarContainer.defaultProps = defaultProps

export default CalendarContainer
