import NextHead from 'next/head'

const Head = () => (
  <NextHead>
    <title>My150</title>
    <meta charSet='utf-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <meta name='theme-color' content='#327CF3' />
    <meta
      name='description'
      content='Building America for over 100 years.'
    />
  </NextHead>
)

export default Head
