import {createSlice} from '@reduxjs/toolkit'

const addressSlice = createSlice({
  name: 'address',
  initialState: {},
  reducers: {
    setAddress: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearAddress: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const addressReducer = addressSlice.reducer
export const {setAddress, clearAddress} = addressSlice.actions
