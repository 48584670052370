import React from 'react'
import SubmitLifeChangingEventModal from './SubmitLifeChangingEventModal'
import {useDispatch, useSelector} from 'react-redux'
import {getShow} from '../../store/selectors/modalSelectors'
import {setShow} from '../../store/slices/modalSlice'
import ScheduleCallModal from './ScheduleCallModal'
import {localise} from '../../services/LocalizationServices'
import {DEPENDENTS_URL, EDIT_URL, FAMILY_URL, NEW_URL} from '../../constants/urls'
import {useRouter} from 'next/router'
import {setCase} from '../../store/slices/caseSlice'
import {clearDependent, setDependent} from '../../store/slices/dependentSlice'
import useAuth from '../../hooks/useAuth'
import {getUnixTimestamp} from '../../utilities/date-time-helpers'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {LCE_TYPE_NEW_MEMBER} from '../../constants/dependents'

export const SUBMIT_LIFE_CHANGING_EVENT_MODAL = 'submit-life-changing-event'
export const ADD_DEPENDENT_MODAL = 'add-dependent'

const FamilyModals = () => {
  const dispatch = useDispatch()
  const show = useSelector(getShow)
  const {push: nav} = useRouter()
  const {user} = useAuth()

  const handleLifeChangingEventValues = values => {
    dispatch(setShow(null))
    toggleLoading()
    dispatch(setCase({
      type: values?.type?.value,
      default_dependent_id: values?.dependent?.value,
      date_of_event: values?.date || '',
      contact_phone_number: values?.phoneNumber,
      best_time_to_call_back: values?.time?.value,
    }))
    toggleLoading()
    nav(`${FAMILY_URL}${EDIT_URL}`)
  }

  const handleScheduleACallValues = values => {
    dispatch(clearDependent())
    dispatch(setDependent({primary_address: user?.primary_address}))
    dispatch(setCase({
      contact_phone_number: values?.phoneNumber,
      best_time_to_call_back: values?.time?.value,
      type: LCE_TYPE_NEW_MEMBER,
    }))
    dispatch(setShow(null))
    nav(`${FAMILY_URL}${EDIT_URL}`)
  }

  return (
    <>
      <SubmitLifeChangingEventModal
        show={show === SUBMIT_LIFE_CHANGING_EVENT_MODAL}
        toggle={() => dispatch(setShow(null))}
        onSubmit={handleLifeChangingEventValues}
      />
      <ScheduleCallModal
        show={show === ADD_DEPENDENT_MODAL}
        toggle={() => dispatch(setShow(null))}
        title={localise('modalText.addDependent')}
        message={localise('modalText.addDependentInfoMsg')}
        onSubmit={handleScheduleACallValues}
      />
    </>
  )
}

export default FamilyModals
