import React, {useContext, useEffect} from 'react'
import {wrapper} from '/store/store'
import {Hydrate, QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query'
import {Toaster} from 'react-hot-toast'
import {ThemeProvider} from 'styled-components'
import theme from '../app-theme.json'
import LoadingIndicator from '../components/loading-indicator/LoadingIndicator'
import GlobalStyles from '../stylesheets/GlobalStyles'
import {AuthProvider} from '../hooks/useAuth'
import GlobalModals from '../components/modals/GlobalModals'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {Provider, useDispatch} from 'react-redux'
import {appWithTranslation, I18nContext} from 'next-i18next'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datepicker/dist/react-datepicker.css'
import {fetchConfiguration} from '../api/configuration/configuration'
import {ONE_HOUR_MS} from '../constants/time'
import {setConfiguration} from '../store/slices/configurationSlice'
import Head from '../components/head/Head'
import {PersistGate} from 'redux-persist/integration/react'
import {Settings} from 'luxon'
import mixpanel from 'mixpanel-browser'
import {NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN} from '../constants/env'
import {useRouter} from 'next/router'
import {IMPERSONATE_URL, MAINTENANCE_URL} from '../constants/urls'
import {Honeybadger, HoneybadgerErrorBoundary} from '@honeybadger-io/react'
import {localise} from '../services/LocalizationServices'

const App = ({Component, ...rest}) => {
  const pageProps = rest?.pageProps

  const i18nContext = useContext(I18nContext)

  const {store} = wrapper.useWrappedStore(rest)

  const [queryClient] = React.useState(() => new QueryClient(
    {
      defaultOptions: {
        queries: {
          enabled: true,
        },
      },
    }
  ))

  const getLayout = Component.getLayout || (page => page)

  const Config = ({children}) => {
    const dispatch = useDispatch()
    const {asPath} = useRouter()

    // Suspend configurations query when handling impersonation.
    // This is to make sure tokens are handled appropriately.
    // We don't need configurations on the impersonation route
    const enabled = ![IMPERSONATE_URL, MAINTENANCE_URL].includes(asPath)

    mixpanel.init(
      NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
      {
        debug: process.env.NODE_ENV === 'development',
        secure_cookie: process.env.NODE_ENV === 'development',
        persistence: 'localStorage',
      }
    )

    const {data} = useQuery(['configurations'], fetchConfiguration, {
      enabled,
      cacheTime: ONE_HOUR_MS,
      staleTime: ONE_HOUR_MS,
    })

    useEffect(() => {
      if (data) dispatch(setConfiguration(data))
      Settings.defaultLocale = navigator?.language || i18nContext?.i18n?.language || 'en-US'
    }, [data, dispatch])

    Honeybadger.beforeNotify(notice => {
      // Don't notify Honeybadger on aborting a route change from the navigation prompt modal
      if (notice?.message?.includes(localise('error.routeChangeAborted'))) {
        return false
      }
    })

    return (
      <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          {children}
        </>
      </HoneybadgerErrorBoundary>
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={store.__persistor}>
        <QueryClientProvider client={queryClient}>
          <Toaster
            position='top-center'
            reverseOrder={false}
            toastOptions={{
              style: {
                maxWidth: 400,
              },
            }}
          />
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={theme}>
              <Config>
                <Head />
                <LoadingIndicator />
                <GlobalStyles />
                <AuthProvider>
                  <GlobalModals />
                  {
                    getLayout(<Component {...pageProps} />)
                  }
                  <ReactQueryDevtools />
                </AuthProvider>
              </Config>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}

export default appWithTranslation(App)
