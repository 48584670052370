import React from 'react'
import {bool, func, node, oneOfType, string} from 'prop-types'
import Label from './Label'

const propTypes = {
  className: string,
  information: oneOfType([func, node]),
  label: string,
  showOptional: bool,
  suppressLocalise: bool,
}

const defaultProps = {
  className: '',
  information: '',
  label: '',
  showOptional: false,
  suppressLocalise: false,
}

const PrimaryLabel = ({className, information, label, showOptional, suppressLocalise}) => (
  <Label
    className={className}
    information={information}
    label={label}
    showOptional={showOptional}
    suppressLocalise={suppressLocalise}
  />
)

PrimaryLabel.propTypes = propTypes
PrimaryLabel.defaultProps = defaultProps

export default PrimaryLabel
