import {configureStore} from '@reduxjs/toolkit'
import reducers from './slices'
import {createWrapper} from 'next-redux-wrapper'
import {CLEAR_STATE} from '../constants/reduxTypes'
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['address', 'beneficiaries', 'case', 'dependent', 'enrollment', 'payment'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    })
  },
})

export default store
export const clearStore = () => store.dispatch({type: CLEAR_STATE})
export const wrapper = createWrapper(() => {
  store.__persistor = persistStore(store)
  return store
})
