import React from 'react'
import CaseCreateModals from './CaseCreateModals'
import useAuth from '../../hooks/useAuth'
import {useSelector} from 'react-redux'
import {getPromptActive} from '../../store/selectors/promptSelectors'
import NavigationPromptModal from './NavigationPromptModal'
import FamilyModals from './FamilyModals'

const GlobalModals = () => {
  const {user} = useAuth()

  const prompt = useSelector(getPromptActive)

  return (
    <>
      {
        user &&
        (
          <>
            <CaseCreateModals />
            <FamilyModals />
          </>
        )
      }
      {prompt && <NavigationPromptModal />}
    </>
  )
}

export default GlobalModals
