import {createGlobalStyle} from 'styled-components'
import {screenMinLg, screenMinMd} from './mixins/screens'

const Utilities = createGlobalStyle`
    .bg-accent {
        background-color: ${props => props?.theme?.colors?.app?.accent} !important;
    }

    .bg-accent-25 {
        background-color: ${props => props?.theme?.colors?.app?.accent25} !important;
    }
        
    .bg-accent-50 {
        background-color: ${props => props?.theme?.colors?.app?.accent50} !important;
    }

    .bg-background {
        background-color: ${props => props?.theme?.colors?.app?.background} !important;
    }
        
    .bg-brand-1 {
        background-color: ${props => props?.theme?.colors?.app?.brand1} !important;
    }

    .bg-brand-1-50 {
        background-color: ${props => props?.theme?.colors?.app?.brand150} !important;
    }

    .bg-brand-1-60 {
        background-color: ${props => props?.theme?.colors?.app?.brand160} !important;
    }

    .bg-brand-1-70 {
        background-color: ${props => props?.theme?.colors?.app?.brand170} !important;
    }

    .bg-brand-2 {
        background-color: ${props => props?.theme?.colors?.app?.brand2} !important;
    }

    .bg-brand-2-50 {
        background-color: ${props => props?.theme?.colors?.app?.brand250} !important;
    }
    
    .bg-destructive-70 {
        background-color: ${props => props?.theme?.colors?.app?.destructive70};
    }
    
    .bg-secondary {
        background-color: ${props => props?.theme?.colors?.app?.secondary} !important;
    }
    
    .border-bottom {
        border-bottom-style: solid !important;
        border-bottom-width: .5px !important;
        border-color: ${props => props?.theme?.colors?.text?.hint} !important;
    }

    .border-top {  
        border-top-style: solid !important;
        border-top-width: .5px !important;
        border-color: ${props => props?.theme?.colors?.text?.hint} !important;
    }
        
    .cursor-pointer {cursor: pointer}
    
    .h-lg-90 {
        ${screenMinLg({height: '90%'})}
    }
    
    .min-vh-90 {
        min-height: 90vh;
    }
    
    .text-transform-none {
        text-transform: none !important;
    }
    
    .white-space-pre-wrap {white-space: pre-wrap}

    .w-10 {
        width: 10%;
    }

    .w-20 {
        width: 20%;
    }
    
    .w-70 {
        width: 70%;
    }
    
    .w-90 {
        width: 90%;
    }

    .w-md-75 {
        ${screenMinMd({width: '75%'})}
    }

    .w-lg-auto {
        ${screenMinLg({width: 'auto !important'})}
    }

    .w-lg-25 {
        ${screenMinLg({width: '25% !important'})}
    }

    .w-lg-35 {
        ${screenMinLg({width: '35% !important'})}
    }
    
    .w-lg-50 {
        ${screenMinLg({width: '50% !important'})}
    }
    
    .w-lg-75 {
        ${screenMinLg({width: '75%'})}        
    }
`

export default Utilities
