import React from 'react'
import {FormGroup} from 'react-bootstrap'
import PrimaryInput from '../../components/inputs/primary/PrimaryInput'
import SecondaryInput from '../../components/inputs/secondary/SecondaryInput'
import PrimaryDropdown from '../../components/inputs/primary/PrimaryDropdown'
import SecondaryDropdown from '../../components/inputs/secondary/SecondaryDropdown'
import PrimaryTelInput from '../../components/inputs/primary/PrimaryTelInput'
import SecondaryTelInput from '../../components/inputs/secondary/SecondaryTelInput'
import PrimaryDatePicker from '../../components/inputs/primary/PrimaryDatePicker'
import SecondaryDatePicker from '../../components/inputs/secondary/SecondaryDatePicker'

export const INPUT_TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  password: 'password',
}

const INPUT_VARIANTS = {
  [INPUT_TYPES.primary]: PrimaryInput,
  [INPUT_TYPES.secondary]: SecondaryInput,
}

const DROPDOWN_VARIANTS = {
  [INPUT_TYPES.primary]: PrimaryDropdown,
  [INPUT_TYPES.secondary]: SecondaryDropdown,
}

const TEL_INPUT_TYPES = {
  [INPUT_TYPES.primary]: PrimaryTelInput,
  [INPUT_TYPES.secondary]: SecondaryTelInput,
}

const DATE_INPUT_VARIANTS = {
  [INPUT_TYPES.primary]: PrimaryDatePicker,
  [INPUT_TYPES.secondary]: SecondaryDatePicker,
}

const handleField = (field, index = 0, v) => {
  const {
    groupClassName,
    label,
    name,
    render,
    type = 'text',
    variant = v,
    ...rest
  } = field

  const handleInputTypes = () => {
    if (type === 'custom') return render

    let Comp
    switch (type) {
      case 'dropdown': Comp = DROPDOWN_VARIANTS[variant]; break
      case 'tel': Comp = TEL_INPUT_TYPES[variant]; break
      case 'date': Comp = DATE_INPUT_VARIANTS[variant]; break
      default: Comp = INPUT_VARIANTS[variant]
    }

    return (
      <Comp
        label={label}
        name={name}
        type={type}
        {...rest}
      />
    )
  }

  return (
    <FormGroup
      controlId={`${label || name}${index}`}
      key={`${label || name}${index}`}
      className={groupClassName}>
      {handleInputTypes()}
    </FormGroup>
  )
}

export const buildForm = (FORM_DATA, v = INPUT_TYPES.primary) => {
  if (Array.isArray(FORM_DATA)) return FORM_DATA.map((field, index) => handleField(field, index, v))
  return handleField(FORM_DATA, 0, v)
}

export default buildForm
