import React, {useEffect, useState} from 'react'
import {array, bool, func, node, oneOf, string} from 'prop-types'
import PrimaryButton from '../buttons/PrimaryButton'
import {localise} from '../../services/LocalizationServices'
import buildForm, {INPUT_TYPES} from '../../utilities/form-helpers/FormBuilder'
import Form from '../form/Form'
import Text from '../text/Text'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import CloseModal from './CloseModal'

const propTypes = {
  bottomSection: node,
  buttonText: string,
  fields: array,
  loading: bool,
  onChange: func,
  onSubmit: func,
  show: bool,
  title: string,
  toggle: func,
  topSection: node,
  isSubmittable: bool,
  variant: oneOf(Object.values(INPUT_TYPES)),
}

const defaultProps = {
  bottomSection: null,
  buttonText: '',
  fields: [],
  loading: false,
  onChange: null,
  onSubmit: null,
  show: false,
  title: '',
  toggle: null,
  topSection: null,
  isSubmittable: false,
  variant: INPUT_TYPES.primary,
}

const InputModal = props => {
  const {
    bottomSection,
    buttonText,
    fields,
    loading,
    onChange,
    onSubmit,
    show,
    title,
    toggle,
    topSection,
    variant,
    isSubmittable,
  } = props

  const [values, setValues] = useState({})
  const [submittable, setSubmittable] = useState(false)
  const [validFields, setValidFields] = useState({})

  useEffect(() => {
    if (show) {
      setValues({})
      setSubmittable(false)
      setValidFields({})
      handleDefaultValues(fields)
      if (onChange) onChange({})
    }
    else {
      // Clear the form when the modal is not shown
      setValues({})
    }
    //eslint-disable-next-line
  }, [show])

  // Check if fields has been updated from the parent and handle if the form is submittable
  useEffect(() => {
    handleSubmittable(values, validFields)
    //eslint-disable-next-line
  }, [fields])

  const handleSubmit = () => {onSubmit(values)}

  const handleValidation = values => {
    const validationFields = fields
      .filter(field => field?.isInvalid)
      .map(({name, isInvalid}) => ({name, isInvalid}))

    const validFields = {}

    for (let field of validationFields) {
      if (typeof field?.isInvalid !== 'function') {
        console.error(`Invalid input field - ${field?.name}. 'isInvalid' attribute must be a function if supplied`)
        validFields[field?.name] = false
      }
      else {
        validFields[field?.name] = field?.isInvalid(values[field?.name])
      }
    }
    setValidFields(validFields)
    handleSubmittable(values, validFields)
  }

  const handleSubmittable = (values, validFields) => {
    const requiredFieldsNames = fields.filter(field => field?.required).map(field => field?.name)
    setSubmittable(
      requiredFieldsNames.every(name => values[name]) &&
      Object.values(validFields).every(val => val === false)
    )
  }

  const handler = (field, val) => {
    const current = values
    current[field.name] = !!field?.formatter ? field?.formatter(val) : val
    setValues(current)
    if (onChange) onChange(current)
    handleValidation(current)
  }

  const handleDefaultValues = fields =>
    fields
      .filter(field => field?.default)
      .forEach(field => handler(field, field?.default))

  return (
    <CloseModal
      show={show}
      toggle={() => {
        // Clear the form when the modal is closed
        setValues({})
        toggle()
      }}
      bodyClasses='px-4 pt-0 pb-3'
      bodyElement={(
        <Form className='px-4'>
          {
            title &&
            <div className='text-center'>
              <Text variant={TEXT_TYPES.subheadBoldPrimary} suppressLocalise>
                {title}
              </Text>
            </div>
          }
          {topSection}
          {
            buildForm(fields.map(field => {
              if (!field?.name) console.error('Invalid input field. A \'name\' attribute must be supplied')
              return {
                ...field,
                formatter: 0,
                isInvalid: validFields[field?.name] || false,
                handler: val => handler(field, val),
                value: values[field?.name] || '',
                ...(
                  field?.type === 'date'
                    ? {
                      onChange: val => handler(field, val),
                      selected: values[field.name],
                      onInvalid: invalid => {
                        const newValidFields = {...validFields, [field?.name]: invalid}
                        setValidFields(newValidFields)
                        handleSubmittable(values, newValidFields)
                      },
                    }
                    : {}
                ),
              }
            }), variant)
          }
          {bottomSection}
        </Form>
      )}
      footerClasses='p-0 px-4 my-4'
      footerElement={(
        <PrimaryButton
          disabled={isSubmittable ? !isSubmittable : !submittable}
          onClick={handleSubmit}
          className='w-75 mx-auto'
          loading={loading}>
          {buttonText || localise('buttonText.submit')}
        </PrimaryButton>
      )}
    />
  )
}

InputModal.propTypes = propTypes
InputModal.defaultProps = defaultProps

export default InputModal
