import styled from 'styled-components'
import {Button as BSButton} from 'react-bootstrap'

const StyledButton = styled(BSButton)`
  &,
  &:focus {
    padding: 12px 55px;
    text-transform: capitalize;
    transition: background-color .2s, color .2s, border .2s;
    border: none !important;
    border-radius: 8px;
  }
  
  &:focus-visible {outline: 2px solid var(--bs-link-color)}

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
  }

  &:active,
  &:hover:not([disabled]) {box-shadow: none}
`

export default StyledButton
