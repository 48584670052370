export default val => {
  val = val.replace(/\D/g, '')
  val = val.replace(/^(\d{2})/, '$1/')
  val = val.replace(/\/(\d{2})/, '/$1/')
  val = val.replace(/(\d)\/(\d{4}).*/, '$1/$2')
  if (val.substring(val.length - 1) === '/') {
    val = val.substring(0, val.length - 1)
  }
  return val
}
