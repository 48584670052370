import React from 'react'
import {array, bool, node, oneOf, string} from 'prop-types'
import styled from 'styled-components'
import useTextStyle, {applyFontToStyle, TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import {useTranslation} from 'next-i18next'

const propTypes = {
  children: node,
  className: string,
  namespaces: array,
  suppressLocalise: bool,
  variant: oneOf(Object.values(TEXT_TYPES)),
}

const defaultProps = {
  children: null,
  className: '',
  namespaces: ['translation'],
  suppressLocalise: false,
  variant: TEXT_TYPES.bodyRegularPrimary,
}

const TextContainer = styled.span`${({$font}) => applyFontToStyle($font)}`

const Text = ({children, className, namespaces, suppressLocalise, variant, ...rest}) => {
  const font = useTextStyle(variant)
  const {t} = useTranslation(namespaces)

  return (
    <TextContainer className={className} $font={font} {...rest}>
      {
        suppressLocalise ? children : t(children)
      }
    </TextContainer>
  )
}

Text.propTypes = propTypes
Text.defaultProps = defaultProps

export default Text
