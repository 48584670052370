import {createSlice} from '@reduxjs/toolkit'

const caseSlice = createSlice({
  name: 'case',
  initialState: {},
  reducers: {
    setCase: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearCase: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const caseReducer = caseSlice.reducer
export const {setCase, clearCase} = caseSlice.actions
