/*
 Reference: https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939
*/
export default date => {
  if (!date) {
    return date
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}
