import React, {useState} from 'react'
import NewCaseModal from './NewCaseModal'
import IconModal from './IconModal'
import {CIRCLE_CHECK_ICON} from '../../constants/images'
import {Stack} from 'react-bootstrap'
import PrimaryButton from '../buttons/PrimaryButton'
import {CASES_URL} from '../../constants/urls'
import SecondaryButton from '../buttons/SecondaryButton'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {createCase} from '../../api/cases/cases'
import {useDispatch, useSelector} from 'react-redux'
import {getShow} from '../../store/selectors/modalSelectors'
import {setShow} from '../../store/slices/modalSlice'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import mixpanel from 'mixpanel-browser'
import {WRP_CASE_TYPE_SLUG} from '../../constants/cases'
import {clearCase} from '../../store/slices/caseSlice'
import useAnalytics from '../../hooks/useAnalytics'
import {ANALYTICS_EVENT} from '../../constants/analytics'

export const NEW_CASE_MODAL = 'new-case'
const NEW_CASE_SUCCESS_MODAL = 'new-case-success'

const CaseCreateModals = () => {
  const queryClient = useQueryClient()
  const {push: nav} = useRouter()
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const show = useSelector(getShow)
  const analytics = useAnalytics()

  const [newCaseId, setNewCaseId] = useState(null)
  const [type, setType] = useState('')

  const mutation = useMutation(payload => createCase(payload), {
    onSuccess: data => {
      analytics(ANALYTICS_EVENT.SUBMIT_CASE_CREATED)
      dispatch(setShow(NEW_CASE_SUCCESS_MODAL))
      dispatch(clearCase())
      setNewCaseId(data?.id)
      setType(data.type)
      mixpanel.track('case_submitted')
    },
  })

  const onSubmit = values => mutation.mutate({
    dependents: values?.dependents?.map(({value}) => value),
    type: values?.type,
    contact_phone_number: values?.phoneNumber,
    best_time_to_call_back: values?.time?.value,
    description: values?.description,
    effective_date: values?.retirementDate?.value,
  })

  return (
    <>
      <NewCaseModal
        show={show === NEW_CASE_MODAL}
        loading={mutation.isLoading}
        toggle={() => dispatch(setShow(null))}
        onSubmit={onSubmit}
      />
      <IconModal
        show={show === NEW_CASE_SUCCESS_MODAL}
        icon={CIRCLE_CHECK_ICON}
        toggle={() => {
          queryClient.invalidateQueries(['cases'])
          dispatch(setShow(null))
        }}
        title={t('modalText.caseSubmitted')}
        message={t(type === WRP_CASE_TYPE_SLUG ? 'modalText.caseSubmittedMsgPrimary' : 'modalText.caseSubmittedMsg')}
        buttons={(
          <Stack gap={3}>
            <PrimaryButton
              className='text-nowrap px-3'
              onClick={() => {
                queryClient.invalidateQueries(['cases'])
                nav(`${CASES_URL}/${newCaseId}`)
                dispatch(setShow(null))
              }}>
              {t('buttonText.viewCaseDetails')}
            </PrimaryButton>
            <SecondaryButton
              className='text-nowrap px-3'
              onClick={() => {
                queryClient.invalidateQueries(['cases'])
                dispatch(setShow(null))
              }}>
              {t('buttonText.close')}
            </SecondaryButton>
          </Stack>
        )}
      />
    </>
  )
}

export default CaseCreateModals
