import React, {useState} from 'react'
import {bool, func, string} from 'prop-types'
import InputModal from './InputModal'
import Text from '../text/Text'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import {localise} from '../../services/LocalizationServices'
import {US_PHONE_REGEX} from '../../constants/regex'
import {INPUT_TYPES} from '../../utilities/form-helpers/FormBuilder'
import {useQuery} from '@tanstack/react-query'
import {fetchCaseTimes} from '../../api/cases/cases'
import {THIRTY_MINUTES_MS} from '../../constants/time'
import useAuth from '../../hooks/useAuth'
import formatPhoneNumber from '../../utilities/form-helpers/formatPhoneNumber'

const propTypes = {
  message: string,
  onSubmit: func,
  show: bool,
  title: string,
  toggle: func,
}

const defaultProps = {
  message: '',
  onSubmit: null,
  show: false,
  title: '',
  toggle: null,
}

const ScheduleCallModal = ({message, onSubmit, show, title, toggle}) => {
  const {user} = useAuth()
  const {
    isLoading: caseTimesLoading,
    data: caseTimesData,
  } = useQuery(['case_times'], fetchCaseTimes, {
    cacheTime: THIRTY_MINUTES_MS,
    staleTime: THIRTY_MINUTES_MS,
  })

  const times = caseTimesData?.times?.map(({name: label, slug: value}) => ({label, value}))

  const fields = [
    {
      name: 'phoneNumber',
      type: 'text',
      required: true,
      label: 'form.label.contactPhoneNumber',
      formatter: formatPhoneNumber,
      default: !!user?.mobile_phone ? formatPhoneNumber(user?.mobile_phone) : '',
      placeholder: 'form.placeholder.enterPhoneNumber',
      isInvalid: mobile => mobile && !US_PHONE_REGEX.test(mobile),
    },
    {
      name: 'time',
      type: 'dropdown',
      required: true,
      label: 'form.label.newCaseBestTimeLabel',
      placeholder: 'form.placeholder.selectATime',
      loading: caseTimesLoading,
      options: times,
    },
  ]

  return (
    <InputModal
      show={show}
      toggle={toggle}
      title={title}
      topSection={(
        message
          ? <Text suppressLocalise className='text-center' variant={TEXT_TYPES.subheadRegularPrimary}>{message}</Text>
          : null
      )}
      fields={fields}
      buttonText={localise('buttonText.next')}
      onSubmit={onSubmit}
      variant={INPUT_TYPES.secondary}
    />
  )
}

ScheduleCallModal.propTypes = propTypes
ScheduleCallModal.defaultProps = defaultProps

export default ScheduleCallModal
