import React, {useEffect, useState} from 'react'
import {bool, func} from 'prop-types'
import InputModal from './InputModal'
import {localise} from '../../services/LocalizationServices'
import {INPUT_TYPES} from '../../utilities/form-helpers/FormBuilder'
import {useQuery} from '@tanstack/react-query'
import {fetchFamilyDependents} from '../../api/dependents/dependents'
import {FIVE_MINUTES_MS, THIRTY_MINUTES_MS} from '../../constants/time'
import {fetchCaseTimes, fetchLifeChangingEventTypes} from '../../api/cases/cases'
import {US_PHONE_REGEX} from '../../constants/regex'
import useAuth from '../../hooks/useAuth'
import formatPhoneNumber from '../../utilities/form-helpers/formatPhoneNumber'
import useAnalytics from '../../hooks/useAnalytics'
import {ANALYTICS_EVENT} from '../../constants/analytics'

const propTypes = {
  onSubmit: func,
  show: bool,
  toggle: func,
}

const defaultProps = {
  onSubmit: null,
  show: false,
  toggle: null,
}

const SubmitLifeChangingEventModal = ({onSubmit, show, toggle}) => {
  const {user} = useAuth()
  const analytics = useAnalytics()

  const [dependentRequired, setDependentRequired] = useState(false)
  const [type, setType] = useState('')

  useEffect(() => {
    if (!show) {
      setDependentRequired(false)
      setType('')
    }
    else {
      analytics(ANALYTICS_EVENT.LCE_SUBMIT_VIEW)
    }
  }, [show])

  const {data, isLoading} = useQuery(['dependents', 'family', type], () => fetchFamilyDependents(type), {
    staleTime: FIVE_MINUTES_MS,
    select: data => ({
      ...data,
      dependents: data?.dependents?.map(({full_name: label, id: value}) => ({label, value})),
    }),
  })

  const triggerSubmit = values => onSubmit(values)

  const {
    data: lceData,
    isLoading: lceLoading,
  } = useQuery(['cases', 'lce_types'], () => fetchLifeChangingEventTypes(), {
    staleTime: THIRTY_MINUTES_MS,
    select: data => ({
      ...data,
      lce_types: data?.lce_types?.map(type => ({
        ...type,
        label: type?.name,
        value: type?.slug,
      })),
    }),
  })

  const {
    isLoading: caseTimesLoading,
    data: caseTimesData,
  } = useQuery(['case_times'], fetchCaseTimes, {
    cacheTime: THIRTY_MINUTES_MS,
    staleTime: THIRTY_MINUTES_MS,
  })

  const dependents = data?.dependents
  const types = lceData?.lce_types
  const times = caseTimesData?.times?.map(({name: label, slug: value}) => ({label, value}))

  const fields = [
    {
      type: 'dropdown',
      name: 'type',
      label: 'form.label.typeOfLifeChangingEventSubmitted',
      placeholder: 'form.placeholder.selectType',
      required: true,
      loading: lceLoading,
      options: types,
    },
    {
      groupClassName: !dependentRequired ? 'd-none' : '',
      type: 'dropdown',
      name: 'dependent',
      label: 'form.label.whoIsLifeChangingEventAbout',
      placeholder: 'form.placeholder.select',
      required: dependentRequired,
      loading: isLoading,
      options: dependents,
    },
    {
      type: 'date',
      name: 'date',
      label: 'form.label.dateOfEvent',
      placeholder: 'form.placeholder.usDateFormat',
      required: true,
      maxDate: new Date(),
    },
    {
      name: 'phoneNumber',
      required: true,
      label: 'form.label.contactPhoneNumber',
      placeholder: 'form.placeholder.enterPhoneNumber',
      error: 'form.validation.phoneNumber',
      formatter: formatPhoneNumber,
      default: !!user?.mobile_phone ? formatPhoneNumber(user?.mobile_phone) : '',
      isInvalid: mobileNumber => mobileNumber && !US_PHONE_REGEX.test(mobileNumber),
    },
    {
      name: 'time',
      type: 'dropdown',
      required: true,
      label: 'form.label.newCaseBestTimeLabel',
      placeholder: 'form.placeholder.selectTime',
      loading: caseTimesLoading,
      options: times,
    },
  ]

  const onChange = values => {
    const type = values?.type

    if (type) {
      setType(type?.value)
      setDependentRequired(type?.dependent_required)
    }
  }

  return (
    <InputModal
      show={show}
      toggle={() => {
        setType(null)
        toggle()
      }}
      title={localise('modalText.submitLifeChangingEvent')}
      buttonText={localise('buttonText.next')}
      onSubmit={triggerSubmit}
      onChange={onChange}
      variant={INPUT_TYPES.secondary}
      fields={fields}
    />
  )
}

SubmitLifeChangingEventModal.propTypes = propTypes
SubmitLifeChangingEventModal.defaultProps = defaultProps

export default SubmitLifeChangingEventModal
