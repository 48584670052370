const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL
const NEXT_PUBLIC_CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID
const NEXT_PUBLIC_CLIENT_SECRET = process.env.NEXT_PUBLIC_CLIENT_SECRET
const NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN
const NEXT_PUBLIC_USE_REFRESH_TOKEN = process.env.NEXT_PUBLIC_USE_REFRESH_TOKEN
const NEXT_PUBLIC_LOGIN_MIGRATION_DISCLAIMER = process.env.NEXT_PUBLIC_LOGIN_MIGRATION_DISCLAIMER
const NEXT_PUBLIC_HONEYBADGER_API_KEY = process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY
const NEXT_PUBLIC_HONEYBADGER_ASSETS_URL = process.env.NEXT_PUBLIC_HONEYBADGER_ASSETS_URL
const NEXT_PUBLIC_HONEYBADGER_REVISION = process.env.NEXT_PUBLIC_HONEYBADGER_REVISION
const NEXT_PUBLIC_ENV = process.env.NEXT_PUBLIC_ENV

export {
  NEXT_PUBLIC_API_URL,
  NEXT_PUBLIC_CLIENT_ID,
  NEXT_PUBLIC_CLIENT_SECRET,
  NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
  NEXT_PUBLIC_USE_REFRESH_TOKEN,
  NEXT_PUBLIC_LOGIN_MIGRATION_DISCLAIMER,
  NEXT_PUBLIC_HONEYBADGER_API_KEY,
  NEXT_PUBLIC_HONEYBADGER_ASSETS_URL,
  NEXT_PUBLIC_HONEYBADGER_REVISION,
  NEXT_PUBLIC_ENV,
}
