import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Image} from 'react-bootstrap'
import {CALENDAR_ICON, CLOSE_ICON} from '../../../../constants/images'
import formatDate from '../../../../utilities/form-helpers/formatDate'
import {localise} from '../../../../services/LocalizationServices'
import {getUnixTimestamp} from '../../../../utilities/date-time-helpers'
import {I18nContext} from 'next-i18next'

const Container = styled.div`
  & ~ button.react-datepicker__close-icon {
    &:after {
      background-image: url(${CLOSE_ICON});
      content: '';
      border-radius: 50%;
      height: 24px;
      width: 24px;
      background-color: transparent;
      transition: background-color .2s,  box-shadow .2s;
    }
    &:hover {
      :after {
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: ${props => props?.theme?.shadows?.buttons};
      }
    }
  }
`

const Input = styled.input`
  height: ${props => props?.theme?.inputs?.height};
  border-width: 1px;
  width: 100%;

  :focus,
  :focus-visible {
    outline: none;
  }
`

const Indicator = styled(Image)`
  position: absolute;
  right: 5px;
  margin-top: 5px;
  &:hover {cursor: pointer}
`

// eslint-disable-next-line react/display-name
const DatePickerInput = forwardRef((props, ref) => {
  const {
    className,
    maxDate,
    minDate,
    onInvalid,
    onChange,
    onClick,
    placeholder,
    readOnly,
    value = '',
  } = props

  const inputRef = useRef(null)
  const i18nContext = useContext(I18nContext)

  const [currentVal, setCurrentVal] = useState('')
  const [cursor, setCursor] = useState(null)

  useEffect(() => {
    setCurrentVal(value)
  }, [value])

  useEffect(() => {
    if (!currentVal) onInvalid(false)
  }, [currentVal])

  useEffect(() => {
    const input = inputRef.current
    if (input) input.setSelectionRange(cursor, cursor)
  }, [inputRef, cursor, currentVal])

  const handler = e => {
    const updatedVal = formatDate(e?.target?.value)
    const date = new Date(updatedVal)
    // Have to take into account the date format here (mm/dd/yyyy) when the user is typing.
    // First check if we're adding characters or removing.
    // Then take account of where the '/' appears
    const cursor =
      updatedVal?.length < currentVal?.length
        ? e.target.selectionStart
        : e.target.selectionStart === 3
          ? 4
          : e.target.selectionStart === 6
            ? 7
            : e.target.selectionStart

    setCursor(cursor)

    if (updatedVal?.length === 10 && date instanceof Date && !isNaN(date)) {
      if (!!maxDate && getUnixTimestamp(date) > getUnixTimestamp(maxDate)) {
        onInvalid(true, localise('form.validation.noFutureDate'))
      }
      else if (!!minDate && getUnixTimestamp(date) < getUnixTimestamp(minDate)) {
        onInvalid(
          true,
          localise('form.validation.minDateError', {
            minDate: minDate.toLocaleDateString(navigator?.language || i18nContext?.i18n?.language || 'en-US'),
          })
        )
      }
      else if (
        date.toLocaleDateString(navigator?.language || i18nContext?.i18n?.language || 'en-US')
        !== updatedVal.replace(/\b0/g, '')
      ) {
        onInvalid(true, localise('form.validation.invalidDate'))
      }
      else {
        onInvalid(false)
        onChange({
          target: {
            value: date,
          },
        })
      }
    }
    else {
      onInvalid(
        !!updatedVal && (updatedVal?.length < 10 || !(date instanceof Date && !isNaN(date))),
        localise('form.validation.invalidDate')
      )
    }

    if (!updatedVal) {
      onChange({
        target: {
          value: '',
        },
      })
    }

    setCurrentVal(updatedVal)
  }

  return (
    <Container ref={ref} className='date-picker-container d-flex' $readOnly={readOnly}>
      <Input
        className={`date-picker-input ${className}`}
        ref={inputRef}
        type='text'
        autoComplete='off'
        readOnly={readOnly}
        placeholder={placeholder}
        value={currentVal}
        onChange={e => handler(e)}
      />
      {
        !value &&
        <Indicator src={CALENDAR_ICON} onClick={onClick} />
      }
    </Container>
  )
})

export default DatePickerInput
