import {createSlice} from '@reduxjs/toolkit'

const filterSlice = createSlice({
  name: 'filter',
  initialState: {},
  reducers: {
    setFilter: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearFilter: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const filterReducer = filterSlice.reducer
export const {setFilter, clearFilter} = filterSlice.actions
