import React from 'react'
import styled from 'styled-components'
import useTextStyle, {applyFontToStyle, TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import Input from '../base/Input'
import SecondaryLabel from '../labels/SecondaryLabel'

const StyledInput = styled(Input)`
  textarea {padding-left: 0}
  
  border-radius: 0;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props?.theme?.colors?.text?.hint};
  padding: 0;
  ${({$font}) => applyFontToStyle($font)}
  
  //readonly styles
  ${props => props?.readOnly ? ({border: 'none !important'}) : null}
 
  :focus {
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${props => props?.theme?.colors?.text?.hint};
  }
  
  ::placeholder {
    ${({$placeholderFont}) => applyFontToStyle($placeholderFont)}
  }

  &:valid {border-bottom-color: ${props => props?.theme?.colors?.text?.hint}}
`

const SecondaryInput = ({information, label, readOnly, required, suppressLocalise, ...rest}) => {
  const font = useTextStyle(TEXT_TYPES.footnoteRegularPrimary)
  const placeholderFont = useTextStyle(TEXT_TYPES.footnoteRegularHint)

  return (
    <>
      <SecondaryLabel
        information={information}
        label={label}
        showOptional={readOnly ? false : !required}
        suppressLocalise={suppressLocalise}
      />
      <StyledInput
        $font={font}
        $placeholderFont={placeholderFont}
        label={label}
        suppressLocalise={suppressLocalise}
        readOnly={readOnly}
        {...rest}
      />
    </>
  )
}

export default SecondaryInput
