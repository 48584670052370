export const MARTIAL_STATUS_MARRIED_SLUG = 'Married'
export const EMPLOYMENT_STATUS_EMPLOYED_SLUG = 'Employed'
export const EMPLOYMENT_STATUS_RETIRED_SLUG = 'Retired'
// TODO: REMOVE WHEN FULLY CONFIRMED
export const EMPLOYMENT_STATUS_PART_TIME_SLUG = 'Part-time'
export const EMPLOYMENT_STATUS_NOT_EMPLOYED_SLUG = 'Not Employed'
export const EMPLOYMENT_STATUS_SELF_EMPLOYED_SLUG = 'Self-Employed'
export const DEPENDENT_RELATIONSHIP_WIFE_SLUG = 'Wife'
export const DEPENDENT_RELATIONSHIP_HUSBAND_SLUG = 'Husband'
export const LCE_TYPE_NEW_MEMBER = 'NewMember'
