import {
  apiClient,
  oauthClient,
  GRANT_TYPE_CLIENT_CREDENTIALS,
  GRANT_TYPE_PASSWORD,
  GRANT_TYPE_AUTHORIZATION_CODE,
} from '../client'
import {toast} from 'react-hot-toast'
import {localise} from '../../services/LocalizationServices'
import handleError from '../../utilities/api-helpers/handleError'
import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {AUTH_URL, SIGN_IN_URL} from '../../constants/urls'

//eslint-disable-next-line
const SCOPE = 'basic profile dues claims hours cases rmsp documents plans beneficiaries household addresses payments notifications'

const redirect = () => {
  /*
  * Redirect if the current location is not an authentication screen or at the app's route.
  * */
  if (
    ![AUTH_URL].some(url => window.location.href.includes(url)) &&
    window.location.href !== `${window.location.origin}/`
  ) {
    window.location.href = `${AUTH_URL}${SIGN_IN_URL}`
    handleError({message: localise('toast.sessionExpiredError')})
  }
}

export const register = payload => tcWrap(() =>
  apiClient.post(
    'users',
    {
      user: payload,
      scope: SCOPE,
    },
    {retryWithClientCredentials: true}
  )
)

export const login = (username, password) => tcWrap(() =>
  oauthClient.post(
    'token',
    {
      grant_type: GRANT_TYPE_PASSWORD,
      username,
      password,
      scope: SCOPE,
    }
  )
)

export const logout = () => oauthClient.post('revoke').catch(handleError)

export const refreshFailed = (err, shouldRedirect = true) => {
  // If err?.shouldRedirect is a boolean value
  // then the app router api proxy has returned this value and takes precedence
  if (typeof err?.shouldRedirect === 'boolean') {
    if (err?.shouldRedirect) redirect()
  }
  else {
    if (shouldRedirect) redirect()
  }
  return Promise.reject(err)
}

//Implement if refresh token is ever used
export const refreshAuthToken = () => {}

export const resetPassword = async email => {
  const handleErr = err => {
    toast.error(err.message || localise('toast.resetPasswordError'))
    return Promise.reject(err)
  }

  const {
    data,
  } = await apiClient.post(
    'users/password',
    {user: {email}},
    {retryWithClientCredentials: true}
  ).catch(handleErr)

  if (!data) return handleErr()
  return data?.message
}

export const resetPasswordWithToken = async (password, password_confirmation, ssn_suffix, reset_password_token) => {
  const {data} = await apiClient.put(
    'users/password',
    {
      user: {
        password,
        password_confirmation,
        reset_password_token,
        ssn_suffix,
      },
    },
    {retryWithClientCredentials: true}
  ).catch(handleError)

  if (!data) return handleError()
  return data?.message
}

export const authenticate = code => {
  const payload = code
    ? {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
      scope: 'basic',
    }
    : {
      grant_type: GRANT_TYPE_CLIENT_CREDENTIALS,
      scope: 'basic',
    }

  return oauthClient.post('token', payload).catch(handleError)
}

export const introspect = token => tcWrap(async () => {
  const {
    data,
  } = await oauthClient.post('introspect', {token}, {retryWithClientCredentials: true}).catch(handleError)
  return data
})

export const acceptInvitation = (password, password_confirmation, ssn_suffix, invitation_token) => tcWrap(async () => {
  const {data} = await apiClient.put(
    'users/invitation',
    {
      user: {
        password,
        password_confirmation,
        invitation_token,
        ssn_suffix,
      },
    },
    {retryWithClientCredentials: true}
  ).catch(handleError)

  if (!data) return handleError()
  return data?.message
})

export const confirmAccount = confirmation_token => tcWrap(async () => {
  const {data} = await apiClient.put(
    'users/confirmation',
    {
      user: {
        confirmation_token,
      },
    },
    {retryWithClientCredentials: true}
  )

  return data?.message
})
