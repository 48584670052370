import React from 'react'
import {func, number, string} from 'prop-types'
import {INFO_ICON} from '../../constants/images'
import {localise} from '../../services/LocalizationServices'
import IconButton from './IconButton'
import styled from 'styled-components'

const propTypes = {
  className: string,
  onClick: func,
  width: number,
}

const defaultProps = {
  className: '',
  onClick: null,
  width: 16,
}

const StyledButton = styled(IconButton)`
  img:hover {
    background-color: transparent;
    box-shadow: none;
    opacity: 0.3;
  }
`

const InfoButton = ({className, onClick, width, ...rest}) => {
  return (
    <StyledButton
      className={className}
      icon={INFO_ICON}
      onClick={onClick}
      width={width}
      alt={localise('alt.infoIcon')}
      {...rest}
    />
  )
}

InfoButton.propTypes = propTypes
InfoButton.defaultProps = defaultProps

export default InfoButton
