import React, {useEffect, useState} from 'react'
import useTextStyle, {applyFontToStyle, TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import {localise as L} from '../../../services/LocalizationServices'
import Error from '../error/Error'
import {bool, func, string} from 'prop-types'
import styled from 'styled-components'
import DatePicker from '../base/date-picker/DatePicker'
import PrimaryLabel from '../labels/PrimaryLabel'

const propTypes = {
  className: string,
  error: string,
  handler: func,
  information: string,
  isInvalid: bool,
  label: string,
  onInvalid: func,
  placeholder: string,
  readOnly: bool,
  required: bool,
  suppressLocalise: bool,
}

const defaultProps = {
  className: '',
  error: '',
  handler: null,
  information: '',
  isInvalid: false,
  label: '',
  onInvalid: null,
  placeholder: null,
  readOnly: false,
  required: false,
  suppressLocalise: false,
}

const StyledDatePicker = styled(DatePicker)`
  &.date-picker-input {
    ${props => props?.readOnly ? {border: 'none'} : {borderColor: props?.theme?.colors?.app?.primary}}
    background-color: transparent;
    border-radius: 4px;
    padding-left: 8px;
    ${({$font}) => applyFontToStyle($font)}
  
    :focus {
      background-color: transparent;
    }
  
    ::placeholder {
      ${({$placeholderFont}) => applyFontToStyle($placeholderFont)}
    }
  
    ${props => props?.$invalid && ({borderColor: props?.theme?.colors?.app?.destructive})}
  }
`

const PrimaryDatePicker = props => {
  const {
    className,
    error,
    handler,
    information,
    isInvalid,
    label,
    onInvalid,
    placeholder = null,
    readOnly,
    required,
    suppressLocalise,
    ...rest
  } = props

  const font = useTextStyle(TEXT_TYPES.footnoteRegularPrimary)
  const placeholderFont = useTextStyle(TEXT_TYPES.footnoteRegularHint)

  const [invalid, setInvalid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (onInvalid) onInvalid(invalid || isInvalid)
  }, [invalid, isInvalid])

  return (
    <>
      <PrimaryLabel
        label={label}
        showOptional={readOnly ? false : !required}
        suppressLocalise={suppressLocalise}
        information={information}
      />
      <StyledDatePicker
        $font={font}
        $placeholderFont={placeholderFont}
        $invalid={isInvalid || invalid}
        className={className}
        onChange={handler}
        placeholder={placeholder === null ? '' : suppressLocalise ? placeholder || label : L(placeholder || label)}
        readOnly={readOnly}
        onInvalid={(isInvalid, message) => {
          setInvalid(isInvalid)
          setErrorMessage(!!isInvalid ? message : '')
        }}
        required={required}
        {...rest}
      />
      {
        error || errorMessage
          ? (isInvalid || invalid) && <Error error={error || errorMessage} suppressLocalise={suppressLocalise} />
          : null
      }
    </>
  )
}

PrimaryDatePicker.propTypes = propTypes
PrimaryDatePicker.defaultProps = defaultProps

export default PrimaryDatePicker
