import React from 'react'
import StyledButton from './StyledButton'
import styled from 'styled-components'
import useTextStyle, {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import Spinner from '../spinner/Spinner'

const Button = styled(StyledButton)`
  &,
  &:focus {
    background-color: transparent;
    box-shadow: ${props => props?.theme?.shadows?.buttons || 'none'};
    color: ${props => props?.$font?.color || 'black'};
    font-size: ${props => props?.$font?.size || '12px'};
    font-weight: ${props => props?.$font?.fontWeight || 'normal'};
    border-style: solid !important;
    border-width: 1px !important;
    border-color: ${props => props?.theme?.colors?.app?.primary} !important;
  }

  &:disabled {
    background-color: transparent;
    color: ${props => `${props?.$hoverFont?.color || props?.$font?.color}80`} !important;
    border-color: ${props => `${props?.$hoverFont?.color || props?.$font?.color}80`} !important;
  }

  &:active,
  &:hover:not([disabled]) {
    background-color: transparent;
    color: ${props => `${props?.$hoverFont?.color || props?.$font?.color}80`} !important;
    border-color: ${props => `${props?.$hoverFont?.color || props?.$font?.color}80`} !important;
  }
`

const SecondaryButton = props => {
  const {
    children,
    disabled,
    loading,
    ...rest
  } = props

  const font = useTextStyle(TEXT_TYPES.subheadBoldPrimary)

  return (
    <Button
      $font={font}
      disabled={disabled || loading}
      {...rest}>
      {
        loading ?
          <Spinner />
          : children
      }
    </Button>
  )
}

export default SecondaryButton
