import Text from '../../text/Text'
import {TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import React from 'react'

const Error = ({error, suppressLocalise}) => (
  <Text
    suppressLocalise={suppressLocalise}
    variant={TEXT_TYPES.footnoteRegularDestructive}>
    {error}
  </Text>
)

export default Error
