import React from 'react'
import {createGlobalStyle} from 'styled-components'
import Utilities from './Utilities'
import Fonts from './Fonts'

const AppStyles = createGlobalStyle`
  html, body {
    background-color: ${props => props?.theme?.colors?.app?.background};
    font-family: ${props => props?.theme?.fonts?.outfit?.family};
    font-weight: normal;
    color: ${props => props?.theme?.colors?.text?.primary};
    -ms-overflow-style: none;
  }

  a {
    color: ${props => props?.theme?.colors?.text?.primary};
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
      color: ${props => props?.theme?.colors?.text?.primary};
    }
  }
`

const GlobalStyles = _ => {
  return (
    <>
      <AppStyles />
      <Fonts />
      <Utilities />
    </>
  )
}

export default GlobalStyles
