import {createSlice} from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    show: null,
  },
  reducers: {
    setShow: (state, action) => void(state.show = action.payload),
    setData: (state, action) => void(state.data = action.payload),
  },
})

export const modalReducer = modalSlice.reducer
export const {setData, setShow} = modalSlice.actions
