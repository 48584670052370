import React from 'react'
import Select from 'react-select'
import useTextStyle, {applyFontToStyle, TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import {useTheme} from 'styled-components'
import DropdownIndicator from '../base/DropdownIndicator'
import {localise as L} from '../../../services/LocalizationServices'
import Error from '../error/Error'
import SecondaryLabel from '../labels/SecondaryLabel'
import Skeleton from 'react-loading-skeleton'

const SecondaryDropdown = props => {
  const {
    className,
    defaultInputValue,
    disabled,
    error,
    handler,
    hideIndicator,
    id,
    information,
    isInvalid,
    label,
    loading,
    options,
    placeholder = null,
    readOnly,
    required,
    suppressLocalise,
    value,
    ...rest
  } = props

  const {colors, inputs, shadows} = useTheme()
  const font = useTextStyle(TEXT_TYPES.footnoteRegularPrimary)
  const placeholderFont = useTextStyle(TEXT_TYPES.footnoteRegularHint)

  const selectStyle = {
    clearIndicator: _ => ({display: 'none'}),
    indicatorSeparator: _ => ({display: 'none'}),
    indicatorsContainer: provided => ({
      ...provided,
      height: inputs?.height,
      display: hideIndicator ? 'none' : 'flex',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: state?.isMulti ? '' : inputs?.height,
      cursor: readOnly ? 'default' : 'pointer',
      paddingLeft: '0',
      overflow: state?.isMulti ? 'auto' : 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      ...applyFontToStyle(font),
      backgroundColor: 'transparent',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      ...(
        readOnly
          ? {borderBottom: 'none'}
          : {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: isInvalid ? colors?.app?.destructive : colors?.text?.hint,
          }
      ),
      borderRadius: '0',
      boxShadow: 'none',
      minHeight: state?.isMulti ? '' : inputs?.height,
      height: state?.isMulti ? '' : inputs?.height,
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    placeholder: provided => ({
      ...provided,
      ...applyFontToStyle(placeholderFont),
    }),
    menu: provided => ({
      borderRadius: '8px',
      backgroundColor: colors?.app?.secondary,
      border: 'none',
      boxShadow: shadows?.dropdownCards,
      marginTop: '5px',
    }),
    menuPortal: provided => ({...provided, zIndex: 9999}),
    option: (provided, state) => ({
      ...provided,
      ...applyFontToStyle(font),
      fontWeight: state.isSelected ? 'bold' : 'normal',
      backgroundColor: state.isSelected ? colors?.app?.accent : colors?.text?.secondary,
      color: state.isSelected ? colors?.text?.secondary : colors?.text?.primary,
      '&:hover': {
        backgroundColor: colors?.app?.accent50,
      },
    }),
  }

  return (
    <>
      <SecondaryLabel
        information={information}
        label={label}
        showOptional={readOnly ? false : !required}
        suppressLocalise={suppressLocalise}
      />
      {
        loading
          ? <Skeleton className='py-3 mt-1' />
          : (
            <Select
              id={id || ''}
              aria-label={(
                label || placeholder === null ? '' : suppressLocalise ? placeholder || label : L(placeholder || label)
              )}
              className={className}
              defaultInputValue={defaultInputValue}
              handler={0}
              isClearable={!readOnly}
              isDisabled={disabled}
              isSearchable={!readOnly}
              key={defaultInputValue}
              onChange={handler}
              openMenuOnClick={!readOnly}
              options={options}
              value={value}
              styles={selectStyle}
              components={{DropdownIndicator: readOnly ? null : DropdownIndicator}}
              placeholder={(
                placeholder === null ? '' : suppressLocalise ? placeholder || label : L(placeholder || label)
              )}
              menuPortalTarget={document.body}
              required={required}
              {...rest}
            />
          )
      }
      {
        !loading && error
          ? isInvalid && <Error error={error} suppressLocalise={suppressLocalise} />
          : null
      }
    </>
  )
}

export default SecondaryDropdown
