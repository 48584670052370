export const UPPER_AND_LOWER_CHARS = /[a-z].*[A-Z]|[A-Z].*[a-z]/
export const CHARS_AND_NUMBERS = /(?=.*[A-Za-z])(?=.*[0-9])/
export const PLUS_SYMBOL_OR_NUMBERS = /(?=.*[+0-9\s])/
// eslint-disable-next-line max-len
export const SPECIAL_CHAR = /(?=.*['ø®å√µ≤≥÷¡€#¢∞¶•ªº–≠[\]\\|\-`œ∑´†¥^π“«æ…¬˚∆˙©ƒ∂ßΩ≈ç~∫¨!@£$%^&*()_+=±§{}":;<>,.?/œ∑´†¥¨])/
// eslint-disable-next-line max-len
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/
// eslint-disable-next-line max-len
export const US_PHONE_REGEX = /^(((\+?1-?\s*)?(\s*\(\s*[2-9]\d{2}\s*\)\s*|[2-9]\d{2})(-|\s+)?[2-9]\d{2}(-|\s+)?\d{4})|((\+?((3\d?)|(4\d?))-?\s*)(\s*\(\s*\d{3}\s*\)\s*|\d{3})(-|\s+)?\d{6,}))$/
export const NUMBERS_ONLY_REGEX = /^[0-9]*$/
