import React from 'react'
import {node} from 'prop-types'
import styled from 'styled-components'
import PrimaryButton from './PrimaryButton'

const propTypes = {children: node}
const defaultProps = {children: null}

const Button = styled(PrimaryButton)`
  &,
  &:focus {
    background-color: ${props => props?.theme?.colors?.app?.destructive || 'red'};
    border-color: ${props => props?.theme?.colors?.app?.destructive} !important;
  }

  &:disabled {
    background-color: ${props => props?.theme?.colors?.app?.destructive50 || 'grey'} !important;
    border-color: ${props => props?.theme?.colors?.app?.destructive50} !important;
  }

  &:active,
  &:hover:not([disabled]) {
    background-color: ${props => props?.theme?.colors?.app?.destructive50 || 'grey'} !important;
    border-color: ${props => props?.theme?.colors?.app?.destructive50} !important;
  }
`

const DestructiveButton = ({children, ...rest}) => {
  return (
    <Button {...rest}>
      {children}
    </Button>
  )
}

DestructiveButton.propTypes = propTypes
DestructiveButton.defaultProps = defaultProps

export default DestructiveButton
