import React from 'react'
import {bool, func, node, oneOf, oneOfType, string} from 'prop-types'
import {FormLabel} from 'react-bootstrap'
import Text from '../../text/Text'
import {TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import InfoTooltip from '../../tooltip/InfoTooltip'
import {localise} from '../../../services/LocalizationServices'
import InfoButton from '../../buttons/InfoButton'

const propTypes = {
  className: string,
  information: oneOfType([func, node]),
  label: string,
  showOptional: bool,
  suppressLocalise: bool,
  variant: oneOf(Object.values(TEXT_TYPES)),
}

const defaultProps = {
  className: '',
  information: '',
  label: '',
  showOptional: false,
  suppressLocalise: false,
  variant: TEXT_TYPES.footnoteBoldPrimary,
}

const Label = ({className, information, label, showOptional, suppressLocalise, variant}) => {
  if (!label) return null

  return (
    <div className='d-flex flex-row align-items-center'>
      <FormLabel
        hidden={!label}
        className={`flex-grow-1 d-flex flex-nowrap ${className}`}>
        <Text
          suppressLocalise={suppressLocalise}
          variant={variant}>
          {label}
        </Text>
        {
          showOptional &&
          <Text
            className='ms-1'
            suppressLocalise
            variant={variant}>
            {`(${localise('form.label.optional')})`}
          </Text>
        }
      </FormLabel>
      {
        information
          ? (
            typeof information !== 'function'
              ? (
                <InfoTooltip
                  id={`info-tooltip-${label?.toLowerCase()?.replaceAll(' ', '-')}`}
                  className='ms-auto mb-1'
                  text={information}
                />
              )
              : (
                <InfoButton
                  className='mb-2'
                  onClick={information}
                />
              )
          )
          : null
      }
    </div>
  )
}

Label.propTypes = propTypes
Label.defaultProps = defaultProps

export default Label
