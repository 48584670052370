import React from 'react'
import {Image} from 'react-bootstrap'
import {ICON_DROPDOWN} from '../../../constants/images'
import {components} from 'react-select'
import {localise} from '../../../services/LocalizationServices'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Image src={ICON_DROPDOWN} alt={localise('alt.dropdownIcon')} />
  </components.DropdownIndicator>
)

export default DropdownIndicator
