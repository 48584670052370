import React from 'react'
import styled from 'styled-components'
import Input from '../base/Input'
import useTextStyle, {applyFontToStyle, TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import PrimaryLabel from '../labels/PrimaryLabel'

const StyledInput = styled(Input)`
  border-color: ${props => props?.theme?.colors?.app?.primary};
  border-radius: 4px;
  ${({$font}) => applyFontToStyle($font)}

  //readonly styles
  ${props => props?.readOnly ? ({border: 'none !important', paddingLeft: '0 !important'}) : null}
 
  :focus {
    border-color: ${props => props?.theme?.colors?.app?.primary};
  }
  
  ::placeholder {
    ${({$placeholderFont}) => applyFontToStyle($placeholderFont)}
  }
  
  &:valid {border-color: ${props => props?.theme?.colors?.app?.primary}}
`

const PrimaryInput = ({information, label, readOnly, required, suppressLocalise, ...rest}) => {
  const font = useTextStyle(TEXT_TYPES.footnoteRegularPrimary)
  const placeholderFont = useTextStyle(TEXT_TYPES.footnoteRegularHint)

  return (
    <>
      <PrimaryLabel
        label={label}
        showOptional={readOnly ? false : !required}
        suppressLocalise={suppressLocalise}
        information={information}
      />
      <StyledInput
        $font={font}
        $placeholderFont={placeholderFont}
        label={label}
        suppressLocalise={suppressLocalise}
        readOnly={readOnly}
        {...rest}
      />
    </>
  )
}

export default PrimaryInput
