import axios from 'axios'
import Qs from 'qs'
import {authenticate, logout, refreshAuthToken, refreshFailed} from './authentication/auth'
import {useRefreshToken} from '../utilities/auth-helpers/authHelpers'
import {MAINTENANCE_URL, NOT_FOUND_URL} from '../constants/urls'
import {MAINTENANCE_MODE_ERROR_CODE, TWO_FACTOR_VERIFY_ERROR_CODE} from '../constants/api'

export const GRANT_TYPE_CLIENT_CREDENTIALS = 'client_credentials'
export const GRANT_TYPE_PASSWORD = 'password'
export const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code'

export const apiClient = (() => {
  let instance

  if (typeof window !== 'undefined') {
    instance = axios.create({
      baseURL: `${window.location.origin}/api/v1`,
    })
  }

  if (instance) {
    instance.interceptors.request.use(
      configuration => {
        configuration.paramsSerializer = params => {
          return Qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false,
          })
        }

        return configuration
      },
      error => Promise.reject(error)
    )

    instance.interceptors.response.use(
      response => response?.data?.data ? {...response?.data?.data, api_response_message: response?.data?.message} : response,
      error => {
        const originalRequest = error.config

        if (error?.response) {
          switch (error.response.status) {
            case 503:
              if (error?.response?.data?.code === MAINTENANCE_MODE_ERROR_CODE) {
                logout()
                  ?.finally(() => {
                    if (window.location.pathname !== MAINTENANCE_URL)
                      window.location.href = MAINTENANCE_URL
                  })
              }
              else if (originalRequest?.onServiceUnavailable) {
                return originalRequest.onServiceUnavailable(error?.response?.data || error?.response)
              }
              else {
                return Promise.reject(error.response.data)
              }
              break
            case 400: return refreshFailed(error.response.data)
            case 403:
              if (error?.response?.data?.code === TWO_FACTOR_VERIFY_ERROR_CODE) {
                return Promise.resolve({
                  code: TWO_FACTOR_VERIFY_ERROR_CODE,
                  ...(error?.response?.data?.data || error?.response),
                })
              }
              else {
                return Promise.reject(error?.response?.data || error?.response)
              }
            case 404:
              if (originalRequest?.onNotFound) {
                return originalRequest.onNotFound(error?.response?.data || error?.response)
              }
              else {
                if (window.location.pathname !== NOT_FOUND_URL)
                  window.location.href = NOT_FOUND_URL
              }
              break
            case 401:
              if (useRefreshToken()) {
                if (!originalRequest.refreshTokenRetried) {
                  originalRequest.refreshTokenRetried = true
                  return refreshAuthToken().then(() => apiClient(originalRequest).then(res => res))
                }
              }
              else if (originalRequest.retryWithClientCredentials) {
                if (!originalRequest.clientCredentialsRetried) {
                  originalRequest.clientCredentialsRetried = true
                  return authenticate().then(() => apiClient(originalRequest).then(res => res))
                }
              }
              else {
                return refreshFailed(error.response.data)
              }
              break
            default:
              return Promise.reject(error.response.data)
          }
        }
      })
  }

  return instance
})()

export const oauthClient = (() => {
  let instance

  if (typeof window !== 'undefined') {
    instance = axios.create({
      baseURL: `${window.location.origin}/api/v1/auth/`,
    })
  }

  if (!!instance) {
    instance.interceptors.request.use(configuration => {
      configuration.paramsSerializer = params => {
        return Qs.stringify(params, {
          arrayFormat: 'brackets',
          encode: false,
        })
      }

      return configuration
    })

    instance.interceptors.response.use(
      response => response?.data?.data || response,
      error => {
        if (error?.response) {
          switch (error.response.status) {
            case 403:
              if (error?.response?.data?.code === TWO_FACTOR_VERIFY_ERROR_CODE) {
                return Promise.resolve({
                  code: TWO_FACTOR_VERIFY_ERROR_CODE,
                  ...(error?.response?.data?.data || error?.response),
                })
              }
              else {
                return Promise.reject(error?.response?.data || error?.response)
              }
            default:
              return Promise.reject(error?.response?.data || error?.response)
          }
        }
      }
    )
  }

  return instance
})()
