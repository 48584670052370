import {createSlice} from '@reduxjs/toolkit'

const beneficiariesSlice = createSlice({
  name: 'beneficiaries',
  initialState: {},
  reducers: {
    setBeneficiaries: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBeneficiaries: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const beneficiariesReducer = beneficiariesSlice.reducer
export const {setBeneficiaries, clearBeneficiaries} = beneficiariesSlice.actions
