import {createSlice} from '@reduxjs/toolkit'

const dependentSlice = createSlice({
  name: 'dependent',
  initialState: {},
  reducers: {
    setDependent: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearDependent: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const dependentReducer = dependentSlice.reducer
export const {setDependent, clearDependent} = dependentSlice.actions
