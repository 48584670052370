import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import {login as apiLogin, logout as apiLogout, register} from '../api/authentication/auth'
import {AUTH_URL, ROOT_URL, SIGN_IN_URL, TWO_FACTOR_URL, VERIFY_URL} from '../constants/urls'
import {deleteUser, fetchUser, updateUser as apiUpdateUser, verifyAccountDetails} from '../api/user/user'
import {TWO_FACTOR_VERIFY_ERROR_CODE} from '../constants/api'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-hot-toast'
import {useRouter} from 'next/router'
import LoadingIndicator from '../components/loading-indicator/LoadingIndicator'
import {Honeybadger} from '@honeybadger-io/react'
import useAnalytics from './useAnalytics'
import {ANALYTICS_EVENT} from '../constants/analytics'
import {useCookies} from 'react-cookie'

const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
  const {push: nav} = useRouter()
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const [cookies] = useCookies(['auth-token-id'])

  const [loading, setLoading] = useState(false)

  const setUser = user => {
    queryClient.setQueryData(['users', 'me'], user)
    Honeybadger.setContext({user_id: user?.id, user_email: user?.email})
  }

  const {isLoading, data: user} = useQuery(['users', 'me'], () => fetchUser(), {
    retry: 0,
    onError: () => setUser(null),
    onSuccess: user => Honeybadger.setContext({user_id: user?.id, user_email: user?.email}),
  })

  const loadUser = () => fetchUser().then(setUser)

  const onLogin = async ({code, two_factor}) => {
    await queryClient.invalidateQueries(['permissions'])
    if (code === TWO_FACTOR_VERIFY_ERROR_CODE) {
      nav(two_factor?.mobile_required ? TWO_FACTOR_URL : `${TWO_FACTOR_URL}${VERIFY_URL}`)
    }
    else {
      loadUser().then(() => nav(ROOT_URL))
    }
  }

  const authMutationSideEffects = {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: onLogin,
    onError: err => {
      toast.error(err?.message)
      setUser(null)
    },
  }

  const signUpMutation = useMutation(
    payload => register(payload),
    {
      ...authMutationSideEffects,
      onSuccess: resp => {
        onLogin(resp)
          .then(() => analytics(ANALYTICS_EVENT.SIGN_UP_REGISTERED))
      },
    }
  )

  const loginMutation = useMutation(
    ({email, password}) => apiLogin(email, password),
    {
      ...authMutationSideEffects,
      onSuccess: resp => {
        onLogin(resp)
          .then(() => analytics(ANALYTICS_EVENT.SIGNED_IN))
      },
    }
  )

  const verifyMutation = useMutation(verifyAccountDetails, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: setUser,
  })

  const login = payload => loginMutation.mutate(payload)

  const signUp = ({memberId, registrationId, socialSecurityLastFour, email, password}) =>
    signUpMutation.mutate({
      ...(
        memberId
          ? {member_id: memberId}
          : {registration_number: registrationId}
      ),
      email,
      password,
      ssn_suffix: socialSecurityLastFour,
    })


  const updateUser = payload => {
    setLoading(true)
    return apiUpdateUser(payload)
      .then(setUser)
      .finally(() => setLoading(false))
  }

  const logout = () => {
    setLoading(true)
    apiLogout()
      .then(() => {
        logoutCleanup()
      })
      .finally(() => setLoading(false))
  }

  const deleteAccount = () => {
    setLoading(true)
    deleteUser().then(() => {
      analytics(ANALYTICS_EVENT.DELETE_ACCOUNT)
      logoutCleanup()
    })
      .finally(() => setLoading(false))
  }

  const logoutCleanup = () => {
    setUser(null)
    // clears the honeybadger context
    Honeybadger.clear()
    nav(`${AUTH_URL}${SIGN_IN_URL}`)
      .then(async () => {
        await queryClient.resetQueries()
        analytics(ANALYTICS_EVENT.SIGN_OUT)
      })
  }

  const verify = () => verifyMutation.mutate()

  const memoValue = useMemo(
    () => ({
      user: user || null,
      loading,
      login,
      signUp,
      logout,
      setUser,
      deleteAccount,
      updateUser,
      verify,
    }),
    //eslint-disable-next-line
    [user, loading])

  return (
    <AuthContext.Provider value={memoValue}>
      {
        !isLoading &&
        !!cookies['auth-token-id']
          ? children
          : <LoadingIndicator active />
      }
    </AuthContext.Provider>
  )
}

const useAuth = _ => useContext(AuthContext)

export default useAuth
