import {LIMIT, SORT} from '../../constants/api'

export default opts => {
  const limit = opts?.limit || LIMIT
  const offset = opts?.offset || 0
  const query = opts?.query || ''
  const sort = opts?.sort || ''

  return {...opts, limit, offset, query, sort}
}
