import {createSlice} from '@reduxjs/toolkit'

const beneficiarySlice = createSlice({
  name: 'beneficiary',
  initialState: {},
  reducers: {
    setBeneficiary: (state, action) => {
      const beneficiary = {...action?.payload}
      if (beneficiary.relationship && typeof beneficiary.relationship === 'string') {
        beneficiary.relationship = {label: beneficiary.relationship, value: beneficiary.relationship}
      }
      Object.keys(beneficiary).map(key => state[key] = beneficiary[key])
    },
    clearBeneficiary: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const beneficiaryReducer = beneficiarySlice.reducer
export const {setBeneficiary, clearBeneficiary} = beneficiarySlice.actions
