import ReactDatePicker from 'react-datepicker'
import DatePickerHeader from './DatePickerHeader'
import CalendarContainer from './CalendarContainer'
import DatePickerInput from './DatePickerInput'
import {convertLocalToUTCDate, convertUTCToLocalDate, getUnixTimestamp} from '../../../../utilities/date-time-helpers'

const DatePicker = props => {
  const {
    className,
    maxDate,
    minDate,
    onChange,
    onInvalid,
    placeholder,
    readOnly,
    selected,
    useUTCUnixDate = true,
    ...rest
  } = props

  return (
    <ReactDatePicker
      className={className}
      id={rest?.id || 'date-picker'}
      showYearDropdown
      customInput={(
        <DatePickerInput
          maxDate={maxDate}
          minDate={minDate}
          onInvalid={onInvalid}
        />
      )}
      renderCustomHeader={props => <DatePickerHeader {...props} />}
      calendarContainer={CalendarContainer}
      placeholderText={placeholder}
      isClearable={!readOnly}
      readOnly={readOnly}
      maxDate={maxDate}
      minDate={minDate}
      selected={(
        useUTCUnixDate && !!selected
          ? convertUTCToLocalDate(new Date(selected * 1000))
          : selected || null
      )}
      onChange={date => (
        useUTCUnixDate
          ? onChange(getUnixTimestamp(convertLocalToUTCDate(date)))
          : onChange(date)
      )}
      {...rest}
    />
  )
}

export default DatePicker
