import {createSlice} from '@reduxjs/toolkit'

const loadingIndicatorSlice = createSlice({
  name: 'loading',
  initialState: {
    active: false,
  },
  reducers: {
    setLoading: state => void(state.active = !state.active),
  },
})

export const loadingIndicatorReducer = loadingIndicatorSlice.reducer
export const {setLoading} = loadingIndicatorSlice.actions
