import React from 'react'
import {bool, func, node, string} from 'prop-types'
import {Image, Stack} from 'react-bootstrap'
import Text from '../text/Text'
import {TEXT_TYPES} from '../../stylesheets/hooks/useTextStyle'
import styled from 'styled-components'
import CloseModal from './CloseModal'

const propTypes = {
  buttons: node,
  icon: string,
  message: string,
  show: bool,
  title: string,
  toggle: func,
}

const defaultProps = {
  buttons: null,
  icon: '',
  message: '',
  show: false,
  title: '',
  toggle: null,
}

const StyledModal = styled(CloseModal)`
  &.modal {
    .modal-dialog {
      margin: auto;
      max-width: 270px;
      top: 10%
    }
  }
`

const IconModal = ({buttons, icon, message, show, title, toggle}) => (
  <StyledModal
    show={show}
    toggle={toggle}
    bodyClasses='p-0'
    bodyElement={(
      <Stack className='align-items-center px-3' gap={3}>
        <Image src={icon} width={44} alt='icon-modal-image' />
        <Text className='my-2 text-center' suppressLocalise variant={TEXT_TYPES.subheadBoldPrimary}>{title}</Text>
        <Text className='text-center white-space-pre-wrap' suppressLocalise variant={TEXT_TYPES.subheadRegularPrimary}>
          {message}
        </Text>
      </Stack>
    )}
    footerClasses='p-0 justify-content-center px-3 my-4'
    footerElement={buttons}
  />
)

IconModal.propTypes = propTypes
IconModal.defaultProps = defaultProps

export default IconModal
