import React, {useEffect, useState} from 'react'
import {bool, func, node, string} from 'prop-types'
// eslint-disable-next-line no-unused-vars
import {Form as BSForm} from 'react-bootstrap'

const propTypes = {
  children: node,
  className: string,
  onSubmit: func,
  suppressValidation: bool,
  validated: bool,
}

const defaultProps = {
  children: null,
  className: '',
  onSubmit: () => {},
  suppressValidation: false,
  validated: false,
}

const Form = ({children, className, disabled, onSubmit, suppressValidation, validated: passedValidated}) => {
  const [validated, setValidated] = useState(passedValidated || false)

  useEffect(_ => {
    setValidated(passedValidated)
  }, [passedValidated])

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    }
    else {
      onSubmit()
    }
    if (!suppressValidation) setValidated(true)
  }

  return (
    <BSForm noValidate validated={validated} onSubmit={handleSubmit} className={className}>
      <fieldset disabled={disabled} className='d-grid gap-4 overflow-hidden'>
        {children}
      </fieldset>
    </BSForm>
  )
}


Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form
