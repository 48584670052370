import React from 'react'
import {func} from 'prop-types'
import Modal from './Modal'
import IconButton from '../buttons/IconButton'
import {CLOSE_ICON} from '../../constants/images'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  toggle: func,
}

const defaultProps = {
  toggle: null,
}

const CloseModal = ({toggle, ...rest}) => {
  return (
    <Modal
      toggle={toggle}
      headerClasses='p-0'
      headerElement={(
        <div className='ms-auto'>
          <IconButton
            onClick={toggle}
            className='me-3 mt-3'
            icon={CLOSE_ICON}
            ariaLabel={localise('aria.close')}
          />
        </div>
      )}
      {...rest}
    />
  )
}

CloseModal.propTypes = propTypes
CloseModal.defaultProps = defaultProps

export default CloseModal
