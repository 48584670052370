import React, {useEffect, useState} from 'react'
import {bool, func, oneOf, string} from 'prop-types'
import PrimaryDropdown from '../primary/PrimaryDropdown'
import SecondaryDropdown from '../secondary/SecondaryDropdown'
import PrimaryInput from '../primary/PrimaryInput'
import SecondaryInput from '../secondary/SecondaryInput'
import PrimaryLabel from '../labels/PrimaryLabel'
import SecondaryLabel from '../labels/SecondaryLabel'
import {Col, Row} from 'react-bootstrap'
import parseTelType from '../../../utilities/form-helpers/parseTelType'
import Error from '../error/Error'

const propTypes = {
  error: string,
  handler: func,
  information: string,
  isInvalid: bool,
  label: string,
  suppressLocalise: bool,
  variant: oneOf(['primary', 'secondary']),
}

const defaultProps = {
  error: '',
  handler: () => {},
  information: null,
  isInvalid: false,
  label: null,
  suppressLocalise: false,
  variant: 'primary',
}

const LABELS = {
  primary: PrimaryLabel,
  secondary: SecondaryLabel,
}

const INPUTS = {
  primary: PrimaryInput,
  secondary: SecondaryInput,
}

const DROPDOWNS = {
  primary: PrimaryDropdown,
  secondary: SecondaryDropdown,
}

const TelInput = props => {
  const {
    error,
    handler,
    information,
    isInvalid,
    label,
    suppressLocalise,
    variant,
  } = props

  const LabelComp = LABELS[variant]
  const InputComp = INPUTS[variant]
  const DropdownComp = DROPDOWNS[variant]

  const [prefix, setPrefix] = useState(null)
  const [number, setNumber] = useState('')
  const [value, setValue] = useState('')

  useEffect(() => {
    if (prefix && number) return setValue(
      `${prefix?.value}${number.indexOf('0') === 0 ? number.substring(1) : number}`
    )
    setValue('')
  }, [prefix, number])

  useEffect(() => {
    handler({value, prefix: prefix?.value, number})
    //eslint-disable-next-line
  }, [handler, value])

  return (
    <div>
      <Row>
        <Col xs='12'>
          <LabelComp
            className='mb-0'
            information={information}
            label={label || 'form.label.mobileNumber'}
            suppressLocalise={suppressLocalise}
          />
        </Col>
        <Col xs='4' className='pe-0'>
          <DropdownComp
            isInvalid={isInvalid}
            suppressLocalise
            placeholder='+000'
            handler={setPrefix}
            hideIndicator={prefix}
            options={[
              {
                label: '(+1) USA',
                value: '+1',
              },
              {
                label: '(+353) Ireland',
                value: '+353',
              },
            ]}
          />
        </Col>
        <Col xs='8'>
          <InputComp
            type='tel'
            isInvalid={isInvalid}
            handler={setNumber}
            value={parseTelType(number)}
          />
        </Col>
      </Row>
      {
        error
          ? isInvalid && <Error error={error || 'form.validation.mobileNumber'} suppressLocalise={suppressLocalise} />
          : null
      }
    </div>
  )
}

TelInput.propTypes = propTypes
TelInput.defaultProps = defaultProps

export default TelInput
