import {createSlice} from '@reduxjs/toolkit'

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState: {},
  reducers: {
    setEnrollment: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearEnrollment: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const enrollmentReducer = enrollmentSlice.reducer
export const {setEnrollment, clearEnrollment} = enrollmentSlice.actions
