import React, {useEffect, useState} from 'react'
import {bool, func, string} from 'prop-types'
import useTextStyle, {applyFontToStyle, TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import SecondaryLabel from '../labels/SecondaryLabel'
import Error from '../error/Error'
import styled from 'styled-components'
import DatePicker from '../base/date-picker/DatePicker'
import {localise as L} from '../../../services/LocalizationServices'

const propTypes = {
  className: string,
  error: string,
  handler: func,
  information: string,
  isInvalid: bool,
  label: string,
  onInvalid: func,
  placeholder: string,
  readOnly: bool,
  required: bool,
  suppressLocalise: bool,
}

const defaultProps = {
  className: '',
  error: '',
  handler: null,
  information: '',
  isInvalid: false,
  label: '',
  onInvalid: null,
  placeholder: null,
  readOnly: false,
  required: false,
  suppressLocalise: false,
}

const borderStyle = props => ({
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: (
    !!props?.$invalid
      ? props?.theme?.colors?.app?.destructive
      : props?.theme?.colors?.text?.hint
  ),
})

const StyledDatePicker = styled(DatePicker)`
  &.date-picker-input {
    border-radius: 0;
    border: none;
    ${props => !props?.readOnly && borderStyle(props)}
    background-color: transparent;
    ${({$font}) => applyFontToStyle($font)}

    :focus,
    :focus-visible {
      background-color: transparent;
      border: none;
      ${props => !props?.readOnly && borderStyle(props)}
    }

    ::placeholder {
      ${({$placeholderFont}) => applyFontToStyle($placeholderFont)}
    }
  }
`

const SecondaryDatePicker = props => {
  const {
    className,
    error,
    handler,
    information,
    isInvalid,
    label,
    onInvalid,
    placeholder = null,
    readOnly,
    required,
    suppressLocalise,
    ...rest
  } = props

  const font = useTextStyle(TEXT_TYPES.footnoteRegularPrimary)
  const placeholderFont = useTextStyle(TEXT_TYPES.footnoteRegularHint)

  const [invalid, setInvalid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (onInvalid) onInvalid(invalid || isInvalid)
  }, [invalid, isInvalid])

  return (
    <>
      <SecondaryLabel
        information={information}
        label={label}
        showOptional={readOnly ? false : !required}
        suppressLocalise={suppressLocalise}
      />
      <StyledDatePicker
        $font={font}
        $placeholderFont={placeholderFont}
        $invalid={isInvalid || invalid}
        className={className}
        onChange={handler}
        placeholder={placeholder === null ? '' : suppressLocalise ? placeholder || label : L(placeholder || label)}
        readOnly={readOnly}
        onInvalid={(isInvalid, message) => {
          setInvalid(isInvalid)
          setErrorMessage(!!isInvalid ? message : '')
        }}
        required={required}
        {...rest}
      />
      {
        error || errorMessage
          ? (isInvalid || invalid) && <Error error={error || errorMessage} suppressLocalise={suppressLocalise} />
          : null
      }
    </>
  )
}

SecondaryDatePicker.propTypes = propTypes
SecondaryDatePicker.defaultProps = defaultProps

export default SecondaryDatePicker
