import React, {useEffect, useState} from 'react'
import {EXCLAMATION_CIRCLE_RED_ICON} from '../../constants/images'
import {localise} from '../../services/LocalizationServices'
import DestructiveButton from '../buttons/DestructiveButton'
import SecondaryButton from '../buttons/SecondaryButton'
import IconModal from './IconModal'
import {useRouter} from 'next/router'
import {useSelector} from 'react-redux'
import {getPromptActive} from '../../store/selectors/promptSelectors'

/*
 * For reference see: https://github.com/vercel/next.js/discussions/32231#discussioncomment-2033546
 */
const NavigationPromptModal = () => {
  const router = useRouter()

  const [show, setShow] = useState(false)
  const [nextRouterPath, setNextRouterPath] = useState(null)
  const prompt = useSelector(getPromptActive)

  const onRouteChangeStart = React.useCallback(
    nextPath => {
      if (!prompt) {
        return
      }

      setShow(true)
      setNextRouterPath(nextPath)

      throw localise('error.routeChangeAborted')
    },
    [prompt]
  )

  const removeListener = () => {
    router.events.off('routeChangeStart', onRouteChangeStart)
  }

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart)

    return removeListener
  }, [onRouteChangeStart])

  const confirm = () => {
    setShow(false)
    // simply remove the listener here so that it doesn't get triggered when we push the new route.
    // This assumes that the component will be removed anyway as the route changes
    removeListener()
    router.push(nextRouterPath)
  }

  const close = () => {
    setNextRouterPath(null)
    setShow(false)
  }

  return (
    <IconModal
      icon={EXCLAMATION_CIRCLE_RED_ICON}
      show={prompt && show}
      toggle={close}
      title={localise('modalText.sureYouWantToCancel')}
      message={localise('modalText.changesMadeNotSaved')}
      buttons={(
        <div className='d-flex flex-column'>
          <DestructiveButton
            className='text-nowrap px-4 text-transform-none'
            onClick={confirm}>
            {
              localise('buttonText.leaveScreen')
            }
          </DestructiveButton>
          <SecondaryButton
            className='text-nowrap mt-2 px-4 text-transform-none'
            onClick={close}>
            {localise('buttonText.stayOnScreen')}
          </SecondaryButton>
        </div>
      )}
    />
  )
}

export default NavigationPromptModal
