import React from 'react'
import {bool, func, number, string} from 'prop-types'
import {Image} from 'react-bootstrap'
import styled from 'styled-components'
import {screenLg} from '../../stylesheets/mixins/screens'

const propTypes = {
  ariaLabel: string,
  className: string,
  disabled: bool,
  icon: string,
  onClick: func,
  width: number,
}

const defaultProps = {
  ariaLabel: '',
  className: '',
  disabled: false,
  icon: '',
  onClick: () => {},
  width: 24,
}

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  min-width: 26px;
  min-height: 26px;
`

const StyledImage = styled(Image)`
  border-radius: 50%;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: ${props => props?.theme?.shadows?.buttons};
  }
  ${screenLg(
    {
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    }
  )}
`

const IconButton = ({ariaLabel, className, disabled, icon, onClick, width, ...rest}) =>
  (
    <StyledButton
      type='button'
      aria-label={ariaLabel}
      className={`align-top ${className} ${!disabled && 'cursor-pointer'}`}
      onClick={event => {
        if (disabled) return null
        else {
          event.stopPropagation()
          onClick(event)
        }
      }}>
      <StyledImage
        src={icon}
        width={width}
        aria-hidden
        focusable={false}
        {...rest}
      />
    </StyledButton>
  )

IconButton.propTypes = propTypes
IconButton.defaultProps = defaultProps

export default IconButton
