const ADD_TO_WALLET_GOOGLE = '/assets/pngs/add-to-wallet-google.png'
const ADD_TO_WALLET_APPLE = '/assets/pngs/add-to-wallet-apple.png'
const ADDRESS_EMPTY_STATE_ICON = '/assets/svgs/address-empty-state-icon.svg'
const BELL_ICON = '/assets/svgs/bell-icon.svg'
const BUILDERS = '/assets/svgs/builders.svg'
const CALENDAR_ICON = '/assets/svgs/calendar-icon.svg'
const CART_ICON = '/assets/svgs/cart-icon.svg'
const CHECK_ACCENT_ICON = '/assets/svgs/check-accent-icon.svg'
const CHECK_CIRCLE_PRIMARY_ICON = '/assets/svgs/check-circle-primary-icon.svg'
const CHECK_HINT_ICON = '/assets/svgs/check-hint-icon.svg'
const CHEVRON_RIGHT_ICON = '/assets/svgs/chevron-right-icon.svg'
const CHEVRON_UP_ICON = '/assets/svgs/chevron-up-icon.svg'
const CHEVRON_UP_DARK_ICON = '/assets/svgs/chevron-up-dark-icon.svg'
const CIRCLE_CHECK_ICON = '/assets/svgs/circle-check-icon.svg'
const CLIPBOARD_ACCENT_ICON = '/assets/svgs/clipboard-accent-icon.svg'
const CLIPBOARD_HINT_ICON = '/assets/svgs/clipboard-hint-icon.svg'
const CLOSE_ICON = '/assets/svgs/close-icon.svg'
const CLOSE_LIGHT_ICON = '/assets/svgs/close-light-icon.svg'
const COINS_ACCENT_ICON = '/assets/svgs/coins-accent-icon.svg'
const COINS_ICON = '/assets/svgs/coins-icon.svg'
const COINS_HINT_ICON = '/assets/svgs/coins-hint-icon.svg'
const COMMENT_DOTS_ICON = '/assets/svgs/comment-dots-icon.svg'
const COMPANY_LOGO = '/assets/pngs/company-logo.png'
const COMPANY_LOGO_DARK = '/assets/svgs/company-logo-dark.svg'
const COMPANY_LOGO_LIGHT = '/assets/svgs/company-logo-light.svg'
const COMPANY_LOGO_ROUND = '/assets/pngs/company-logo-round.png'
const CONTACT_US_HELP = '/assets/svgs/contact-us-help.svg'
const CRANE = '/assets/pngs/crane.png'
const DELINQUENT_PAYMENT = '/assets/svgs/delinquent_payment.svg'
const DESKTOP_ICON = '/assets/svgs/desktop-icon.svg'
const DOCUMENT_ACCEPTED_ICON = '/assets/svgs/document-accepted-icon.svg'
const DOCUMENT_PENDING_ICON = '/assets/svgs/document-pending-icon.svg'
const DOCUMENT_REJECTED_ICON = '/assets/svgs/document-rejected-icon.svg'
const DOWNLOAD_ICON = '/assets/svgs/download_icon.svg'
const ELLIPSIS_ICON = '/assets/svgs/ellipsis-icon.svg'
const ENVELOPE_ICON = '/assets/svgs/envelope-icon.svg'
const ENROLLMENT_SUCCESS = '/assets/svgs/enrollment-success.svg'
const EXCLAMATION_CIRCLE_PRIMARY_ICON = '/assets/svgs/exclamation-circle-primary-icon.svg'
const EXCLAMATION_CIRCLE_RED_ICON = '/assets/svgs/exclamation-circle-red-icon.svg'
const EXCLAMATION_CIRCLE_RED_LIGHT_ICON = '/assets/svgs/exclamation-circle-red-light-icon.svg'
const FAQS_HELP = '/assets/pngs/faqs-help.png'
const FAX_ICON = '/assets/svgs/fax-icon.svg'
const GENERAL_ERROR_ICON = '/assets/svgs/general-error-icon.svg'
const ICON_DROPDOWN = '/assets/svgs/dropdown-icon.svg'
const INFO_ICON = '/assets/svgs/info-icon.svg'
const LANDING_IMAGE = '/assets/pngs/landing-image.png'
const LINK_ICON = '/assets/svgs/link_icon.svg'
const MAP_MARKER_ICON = '/assets/svgs/map-marker-icon.svg'
const MEDICAL_FILE_ACCENT_ICON = '/assets/svgs/medical-file-accent-icon.svg'
const MEDICAL_FILE_HINT_ICON = '/assets/svgs/medical-file-hint-icon.svg'
const MEDICAL_FILES_ACCENT_ICON = '/assets/svgs/medical-files-accent-icon.svg'
const MEDICAL_FILES_HINT_ICON = '/assets/svgs/medical-files-hint-icon.svg'
const MORE_INFO_MOE_ID = '/assets/pngs/more-info-moe-id.png'
const MORE_INFO_REG_NO = '/assets/pngs/more-info-reg-no.png'
const NOTIFICATION_ICON = '/assets/svgs/notification-icon.svg'
const NOTIFICATION_ICON_ALERT = '/assets/svgs/notification-icon-alert.svg'
const NOTIFICATION_ICON_HINT = '/assets/svgs/notification-icon-hint.svg'
const PEN_ICON = '/assets/svgs/pen-icon.svg'
const PEN_ACCENT_ICON = '/assets/svgs/pen-accent-icon.svg'
const PHONE_ICON = '/assets/svgs/phone-icon.svg'
const PROFILE_PLACEHOLDER_ICON = '/assets/svgs/profile-placeholder-icon.svg'
const QUESTION_CIRCLE_ICON = '/assets/svgs/question-circle-icon.svg'
const QUESTION_CIRCLE_ICON_BRAND_1 = '/assets/svgs/question-circle-icon-brand-1.svg'
const REGULAR_ENVELOPE_OPEN_ICON = '/assets/svgs/regular-envelope-open-icon.svg'
const SEARCH_ICON = '/assets/svgs/search-icon.svg'
const SHIELD_CHECK_ICON = '/assets/svgs/shield-check-icon.svg'
const SIGNATURE_FILE_ACCENT_ICON = '/assets/svgs/signature-file-accent-icon.svg'
const SIGNATURE_FILE_HINT_ICON = '/assets/svgs/signature-file-hint-icon.svg'
const STOPWATCH_ICON = '/assets/svgs/stopwatch-icon.svg'
const SUCCESS_CHECK_ICON = '/assets/svgs/success-check-icon.svg'
const SUCCESS_SHIELD_ICON = '/assets/svgs/success-shield-icon.svg'
const TABLE_EMPTY_STATE = '/assets/svgs/table-empty-state.svg'
const TWO_FACTOR_AUTH = '/assets/svgs/two-factor-auth.svg'
const UPCOMING_EVENTS = '/assets/pngs/upcoming-events.png'
const USER_CIRCLE_ICON = '/assets/svgs/user-circle-icon.svg'
const USER_EDIT_ACCENT_ICON = '/assets/svgs/user-edit-accent-icon.svg'
const USER_EDIT_HINT_ICON = '/assets/svgs/user-edit-hint-icon.svg'
const PASSWORD_SHOW_ICON = '/assets/svgs/password-show-icon.svg'
const PASSWORD_HIDE_ICON = '/assets/svgs/password-hide-icon.svg'

export {
  ADD_TO_WALLET_GOOGLE,
  ADD_TO_WALLET_APPLE,
  ADDRESS_EMPTY_STATE_ICON,
  BELL_ICON,
  BUILDERS,
  CALENDAR_ICON,
  CART_ICON,
  CHECK_ACCENT_ICON,
  CHECK_CIRCLE_PRIMARY_ICON,
  CHECK_HINT_ICON,
  CHEVRON_RIGHT_ICON,
  CHEVRON_UP_ICON,
  CHEVRON_UP_DARK_ICON,
  CIRCLE_CHECK_ICON,
  CLIPBOARD_ACCENT_ICON,
  CLIPBOARD_HINT_ICON,
  CLOSE_ICON,
  CLOSE_LIGHT_ICON,
  COINS_ACCENT_ICON,
  COINS_ICON,
  COINS_HINT_ICON,
  COMMENT_DOTS_ICON,
  COMPANY_LOGO,
  COMPANY_LOGO_DARK,
  COMPANY_LOGO_LIGHT,
  COMPANY_LOGO_ROUND,
  CONTACT_US_HELP,
  CRANE,
  DELINQUENT_PAYMENT,
  DESKTOP_ICON,
  DOCUMENT_ACCEPTED_ICON,
  DOCUMENT_PENDING_ICON,
  DOCUMENT_REJECTED_ICON,
  DOWNLOAD_ICON,
  ELLIPSIS_ICON,
  ENVELOPE_ICON,
  ENROLLMENT_SUCCESS,
  EXCLAMATION_CIRCLE_PRIMARY_ICON,
  EXCLAMATION_CIRCLE_RED_ICON,
  EXCLAMATION_CIRCLE_RED_LIGHT_ICON,
  FAQS_HELP,
  FAX_ICON,
  GENERAL_ERROR_ICON,
  ICON_DROPDOWN,
  INFO_ICON,
  LANDING_IMAGE,
  LINK_ICON,
  MAP_MARKER_ICON,
  MEDICAL_FILE_ACCENT_ICON,
  MEDICAL_FILE_HINT_ICON,
  MEDICAL_FILES_ACCENT_ICON,
  MEDICAL_FILES_HINT_ICON,
  MORE_INFO_MOE_ID,
  MORE_INFO_REG_NO,
  NOTIFICATION_ICON,
  NOTIFICATION_ICON_ALERT,
  NOTIFICATION_ICON_HINT,
  PEN_ICON,
  PEN_ACCENT_ICON,
  PHONE_ICON,
  PROFILE_PLACEHOLDER_ICON,
  QUESTION_CIRCLE_ICON,
  QUESTION_CIRCLE_ICON_BRAND_1,
  REGULAR_ENVELOPE_OPEN_ICON,
  TABLE_EMPTY_STATE,
  SEARCH_ICON,
  SHIELD_CHECK_ICON,
  SIGNATURE_FILE_ACCENT_ICON,
  SIGNATURE_FILE_HINT_ICON,
  STOPWATCH_ICON,
  SUCCESS_CHECK_ICON,
  SUCCESS_SHIELD_ICON,
  TWO_FACTOR_AUTH,
  UPCOMING_EVENTS,
  USER_CIRCLE_ICON,
  USER_EDIT_ACCENT_ICON,
  USER_EDIT_HINT_ICON,
  PASSWORD_HIDE_ICON,
  PASSWORD_SHOW_ICON,
}
