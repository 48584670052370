import getIndexParams from '../../utilities/api-helpers/getIndexParams'
import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchCases = opts => tcWrap(async () => {
  const params = getIndexParams(opts)

  const {cases, meta: {total}} = await apiClient.get('cases', {params}).catch(handleError)
  return {cases, total}
})

export const fetchCaseTimes = () => tcWrap(async () => {
  const {times, meta: {total}} = await apiClient.get('cases/times').catch(handleError)
  return {times, total}
})

export const fetchCaseEffectiveDates = () => tcWrap(async () => {
  const {effective_dates, meta: {total}} = await apiClient.get('cases/effective_dates').catch(handleError)
  return {effective_dates, total}
})

export const fetchCaseDependents = id => tcWrap(async () => {
  const {dependents, meta: {total}} = await apiClient.get(`cases/${id}/dependents`).catch(handleError)
  return {dependents, total}
})

export const fetchLifeChangingEventTypes = () => tcWrap(async () => {
  const {
    lce_types,
    meta: {total},
  } = await apiClient.get('cases/life_changing_event_types').catch(handleError)

  return {lce_types, total}
})

const caseAction = (action, url, data) => tcWrap(async () => {
  const {case: thisCase} = await apiClient[action](url, data).catch(handleError)
  return thisCase
})

export const fetchCase = id => caseAction('get', `cases/${id}`)
export const createCase = payload => caseAction('post', 'cases', {case: payload})
export const createLifeChangingEvent = payload => caseAction('post', 'cases/life_changing_event', {case: payload})
