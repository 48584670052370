import React from 'react'
import {bool, func, node, oneOfType, string} from 'prop-types'
import Label from './Label'
import {TEXT_TYPES} from '../../../stylesheets/hooks/useTextStyle'
import styled from 'styled-components'

const propTypes = {
  className: string,
  information: oneOfType([func, node]),
  label: string,
  showOptional: bool,
  suppressLocalise: bool,
}

const defaultProps = {
  className: '',
  information: '',
  label: '',
  showOptional: false,
  suppressLocalise: false,
}

const StyledLabel = styled(Label)`margin-bottom: 0`

const SecondaryLabel = ({className, information, label, showOptional, suppressLocalise}) => (
  <StyledLabel
    className={className}
    information={information}
    label={label}
    showOptional={showOptional}
    suppressLocalise={suppressLocalise}
    variant={TEXT_TYPES.footnoteRegularHint}
  />
)

SecondaryLabel.propTypes = propTypes
SecondaryLabel.defaultProps = defaultProps

export default SecondaryLabel
