import React from 'react'
import mixpanel from 'mixpanel-browser'

const useAnalytics = () => {
  return (event, params = null) => {
    mixpanel.track(event, params)
  }
}

export default useAnalytics
