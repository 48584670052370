import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchDependents = (include_primary = true) => tcWrap(async () => {
  const {dependents, meta: {total}} = await apiClient.get('dependents', {params: {include_primary}}).catch(handleError)
  return {dependents, total}
})

export const fetchDependentGenders = () => tcWrap(async () => {
  const {genders, meta: {total}} = await apiClient.get('dependents/genders').catch(handleError)
  return {genders, total}
})

export const fetchDependentRelationships = () => tcWrap(async () => {
  const {relationships, meta: {total}} = await apiClient.get('dependents/relationships').catch(handleError)
  return {relationships, total}
})

export const fetchFamilyDependents = (lce_type = '', selected_dependent = '') => tcWrap(async () => {
  const {dependents, meta: {total}} = await apiClient.get(
    'dependents/family', {params: {lce_type, selected_dependent}}).catch(handleError)
  return {dependents, total}
})

export const fetchFsbDependents = () => tcWrap(async () => {
  const {dependents, meta: {total}} = await apiClient.get(
    'dependents', {params: {fsb_eligible: true}}).catch(handleError)
  return {dependents, total}
})

const dependentAction = (action, url, data) => tcWrap(async () => {
  const {dependent} = await apiClient[action](url, data).catch(handleError)
  return dependent
})

export const fetchCobDependents = () => tcWrap(async () => {
  const {
    dependents,
    meta: {total},
  } = await apiClient.get('dependents', {params: {cob_eligible: true}}).catch(handleError)
  return {dependents, total}
})

export const fetchDependent = id => dependentAction('get', `dependents/${id}`)
export const createDependent = payload => dependentAction('post', 'dependents', payload)
export const updateDependent = (id, payload) => dependentAction('put', `dependents/${id}`, payload)
export const createCob = (id, payload) => dependentAction('post', `dependents/${id}/coordination_of_benefits`, payload)

export const fetchDependentMaritalStatuses = () => tcWrap(async () => {
  const {marital_statuses, meta: {total}} = await apiClient.get('dependents/marital_statuses').catch(handleError)
  return {marital_statuses, total}
})

export const fetchDependentEmploymentStatuses = id => tcWrap(async () => {
  const {
    employment_statuses,
    meta: {total},
  } = await apiClient.get(`dependents/${id}/employment_statuses`).catch(handleError)
  return {employment_statuses, total}
})

export const fetchCoverageTypes = () => tcWrap(async () => {
  const {coverage_types, meta: {total}} = await apiClient.get('dependents/coverage_types').catch(handleError)
  return {coverage_types, total}
})

export const fetchCoverageTiers = () => tcWrap(async () => {
  const {coverage_tiers, meta: {total}} = await apiClient.get('dependents/coverage_tiers').catch(handleError)
  return {coverage_tiers, total}
})

export const fetchTrustedDependents = (trusted_only = true) => tcWrap(async() => {
  const {
    dependents,
    meta: {total},
  } = await apiClient.get('dependents/trusted_dependents', {params: {trusted_only}}).catch(handleError)

  return {dependents, total}
})

export const fetchAllTrustedDependents = () => tcWrap(async() => {
  const {dependents,
    meta: {total}} = await apiClient.get('dependents/trusted_dependents').catch(handleError)

  return {dependents, total}
})

export const updateTrustedDependents = payload => tcWrap(async() => {
  const {
    trusted_dependents,
  } = await apiClient.put('dependents/trusted_dependents', {trusted_dependents: payload}).catch(handleError)

  return trusted_dependents
})

